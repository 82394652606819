import React from "react";
import {useTranslation} from "react-i18next";

const PubSidebarContact = (props: any) => {
  const {t} = useTranslation();
  const agencyPhoneNo: string = props.phone;
  return (
      <React.Fragment>
        {agencyPhoneNo && (
            <div>
              {/*  Contact Section  */}
              <b>{t("contact")}</b>
              <br/>
              {/* @todo Fix the static 911 phone no. */}
              <span>{`${t("emergency")}: 9 - 1 - 1`}</span>
              <br/>
              <span>{`${t("non_emergency")}: ${agencyPhoneNo}`}</span>
            </div>
        )}
      </React.Fragment>
  );
};

export default PubSidebarContact;
