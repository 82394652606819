import { Reducer } from 'redux';
import { UserActions, UserActionTypes} from "../actions/user";

export interface IUser{
    error?: string | null,
    verified: boolean,
    first_name: string,
    last_name: string,
    username: string,
    session:string
  }


  // Define the login State
export interface IUserState {
    readonly user: IUser;
  }

  // Define the initial state
const initialUserState: IUserState = {
    user:{    
      verified: false,
      first_name: "",
      last_name: "",
      username: "",
      session:""
    }
  };

  export const userReducer: Reducer<IUserState, UserActions> = (
    state = initialUserState,
    action
  ) => {
    switch (action.type) {
      case UserActionTypes.VALIDATE_STATUS: {
        return {
            ...state,
            user: {
              ...state.user,
              verified : action.user.verified,
              first_name: action.user.first_name,
              last_name: action.user.last_name,
              username: action.user.username,
              session: action.user.session
          }
        }
        };
      default:
        return state;
    }
  };