import React from "react";
import {Col, Row} from "@eb/antd";
import MenuComponent from "./Menu-component";
import {Content} from "@eb/antd/es/layout/layout";
import { IAppState } from '../../store/store';
import { connect} from 'react-redux';

const LayoutSectionComponent = (props: any) => {

  const sideMenuBreakPoint: number = 768;
  const windowWidth = props.application.windowWidth;
  const isUserLoggedIn: boolean = props.login.isLoggedIn;
  const isSideMenuEnable = windowWidth > sideMenuBreakPoint;
  return (
      <Row>
        {
          isSideMenuEnable && isUserLoggedIn &&
          <Col flex="65px" style={{backgroundColor: "white"}}><MenuComponent/></Col>
        }
        <Col flex="auto">
          <Content className="container">
            <div id="main" className="site-layout-content"> {props.children} </div>
          </Content>
        </Col>
      </Row>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    login: store.loginState.login,
    application : store.applicationState.application
  };
};

export default connect(mapStateToProps)(LayoutSectionComponent);
