import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";
import {IAlert,IAlertState} from '../reducers/alert'
import {changeLanguage} from "../i18n/config";
import {LanguageSet,translationSeperator,_baseURL,_env} from "./default.config";

// Import the store function and state

import {store} from '../index'

export enum AlertActionTypes {
    FETCH_ALERT = "fetch_alert",
    ENABLE_LOADING = "loading_lang",
    LOADING_DONE = "loading_done",
    SET_LANGUAGE = "set_language"
}

export interface IAlertAction {
    type: AlertActionTypes;
    alert: IAlert;
    setLang : LanguageSet;
}

export type AlertAction = IAlertAction

export const fetchPubDataByID: ActionCreator<
ThunkAction<Promise<any>, IAlertState, null, IAlertAction>
> = (pubId: string) => {

        // @ts-ignore
        const URL = _baseURL + _env.REACT_APP_API_GET_PUBLICATION + pubId;
        
        return async (dispatch: Dispatch) => {
            try {
                const response = await axios.get(URL);
                dispatch({
                    alert : response.data ,
                    type: AlertActionTypes.FETCH_ALERT,
                  })
                  changeLanguage(LanguageSet.EN);
            }
            catch(err){
                console.log(err)
            }
  };
};

export const getTranslatedText: ActionCreator<
ThunkAction<Promise<any>, IAlertState, null, IAlertAction>
> = (targetLang: LanguageSet = LanguageSet.ES) => {

    return async (dispatch: Dispatch) => {

        let translatedData: string[] = [];
        
        // @ts-ignore
        const BASE_URL = _env.REACT_APP_GOOGLE_TRANSLATE_API_URL;
        // @ts-ignore
        const API_KEY = _env.REACT_APP_GOOGLE_TRANSLATION_API_KEY;
        // @ts-ignore
        const URL = `${BASE_URL}/?key=${API_KEY}`;

        const processGroups = processTranslatesArr();
        
        if (targetLang !== LanguageSet.EN) {
            if (store.getState().alertState.alert.lng) {
                changeLanguage(targetLang);
            } 
            else {
            const response = await axios.post(URL, {
                format: "html",
                source: LanguageSet.EN,
                target: targetLang,
                q: processGroups.values.join(translationSeperator),
            });
            
            const translatedRaw: string = response?.data?.data?.translations[0]?.translatedText;
            const translatedDataAr: string[] = translatedRaw.split(translationSeperator);
        
            let newTranslatedObj = {};

            processGroups.keys.forEach((_e: any, idx: number) => {
                // @ts-ignore
                newTranslatedObj[_e] = translatedDataAr[idx];
            });

            translatedData = store.getState().alertState.alert[LanguageSet.EN];
            translatedData = Object.assign(
                {},
                {...translatedData},
                {...newTranslatedObj}
            );

            
        }

        } 

        dispatch({
            type : AlertActionTypes.SET_LANGUAGE,
            setLang : targetLang,
            alert : translatedData
        })

        changeLanguage(targetLang);

    }
}


const processTranslatesArr = (): any => {

    const englishDataSet = store.getState().alertState.alert[LanguageSet.EN] ;

    let keyArr = [
      "published_str",
      "headline",
      "pub_text",
      "cap_instructions",
      "incident_entered",
      "severity_string",
      "urgency_string",
      "certainty_string",
      "category_string",
      "cap_event"
    ];

    
    let valueArr = keyArr.map((keyEach) => {
      // @ts-ignore
     return englishDataSet[keyEach] ? englishDataSet[keyEach] : "";
    });
    return {keys: keyArr, values: valueArr};
  };


