import { ActionCreator, Dispatch,bindActionCreators } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";
import {ILogin,ILoginState} from "../reducers/login"
import {resetUser} from "./user"
import {store} from '../index'
import { _baseURL,_env} from './default.config';

const validatedHeaderParams: object = {
  credentials: 'include',
  headers: {
    mode: 'cors'
  }
};

export enum LoginActionTypes {
  SEND_LOGIN = "send_login",
  LOGIN_USER = "login_user",
  FETCH_STATUS = "fetch_status",
  LOGOUT_USER = "logout_user",
  DECREASE_TIMER = "decrease_timer",
  RESET_LOGIN = "reset_login",
  VERIFY_TOKEN = "verify_token",
  RESTART_ACTIVE = "restart_active",
  LOGIN_TIMEOUT = "login_timeout"
}

export interface ILoginAction {
  type: string;
  login?: ILogin[];
}

export type LoginActions = ILoginAction;

export const sendLoginPhoneNo: ActionCreator<
  ThunkAction<Promise<any>, ILoginState, null, ILoginAction>
> = (phNo:any) => {
  // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_GET_LOGIN + phNo;

  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(URL);

      if(response.data ===1){
        dispatch({
          login: response.data.results,
          type: LoginActionTypes.SEND_LOGIN,
        });
     }

    } catch (err) {
      console.error(err);
    }
  };
};

export const decreaseTimerBy1Seconds = () => {
  return async (dispatch: Dispatch) => {
      dispatch({
        type: LoginActionTypes.DECREASE_TIMER,
      })
  };
};

export const resetLoginFlags =() =>{
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoginActionTypes.RESET_LOGIN,
    })
};
}

export const verifyUserLoginToken : ActionCreator<
ThunkAction<Promise<any>, ILoginState, null, ILoginAction>
> = (userToken: string) => {

 // @ts-ignore
 const URL = _baseURL + _env.REACT_APP_API_VERIFY_TOKEN + userToken

 return async (dispatch: Dispatch) => {
   try {
     const response = await axios.get(URL);

     if(response.data.verified){
       dispatch({
         type: LoginActionTypes.VERIFY_TOKEN,
       });
    }

   } catch (err) {
     console.error(err);
   }
 };
}

export const setRestartActive =() =>{
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoginActionTypes.RESTART_ACTIVE,
    })
};
}

export const setLoginTimeout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoginActionTypes.LOGIN_TIMEOUT,
    })
};
}

export const loginUser =() =>{
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LoginActionTypes.LOGIN_USER,
    })
};
}

export const logoutUser =() =>{
  
  const actions = bindActionCreators({resetLoginFlags, resetUser}, store.dispatch);

  return async () => {
        // @ts-ignore
        const URL = _baseURL + _env.REACT_APP_API_GET_LOGOUT;
        const response = await axios.get(URL, validatedHeaderParams);

        if (response.data && response.data.hasOwnProperty('session')) {
          actions.resetLoginFlags();
          actions.resetUser();
          localStorage.removeItem('persist:login');
          localStorage.removeItem('persist:root');
      }
      return response;
};
}