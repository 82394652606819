import { Reducer } from 'redux';
import { LoginActions, LoginActionTypes} from "../actions/login";

const MAX_TIMER_VAlUE: number = 60 * 5;

export interface ILogin {
  phoneNoValidationRemaining: number;
  isRestartActive: boolean;
  phoneNoSubmit: boolean;
  phoneNoLinkSent: boolean;
  phoneNoValidated: boolean;
  phoneNoValidateError: boolean;
  errorMsg: string;
  isLoggedIn: boolean;
}

// Define the login State
export interface ILoginState {
  readonly login: ILogin;
}


// Define the initial state
const initialLoginState: ILoginState = {
  login: {
    phoneNoValidationRemaining: 0,
    isRestartActive: false,
    phoneNoSubmit: false,
    phoneNoLinkSent: false,
    phoneNoValidated: false,
    phoneNoValidateError: false,
    errorMsg: "",
    isLoggedIn: false
  },
};



export const loginReducer: Reducer<ILoginState, LoginActions> = (
  state = initialLoginState,
  action
) => {
  switch (action.type) {

    case LoginActionTypes.SEND_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          phoneNoSubmit: true,
          phoneNoLinkSent: true,
          phoneNoValidationRemaining: MAX_TIMER_VAlUE
        },
      };
    }
    case LoginActionTypes.DECREASE_TIMER: {
      return {
        ...state,
        login: {...state.login, phoneNoValidationRemaining: --state.login.phoneNoValidationRemaining},
      };
    }
    case LoginActionTypes.RESET_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          phoneNoValidationRemaining: 0,
          isRestartActive: false,
          phoneNoValidateError: false,
          phoneNoSubmit: false,
          phoneNoLinkSent: false,
          phoneNoValidated: false,
          errorMsg: '',
          isLoggedIn: false
        },
      };
    }
    case LoginActionTypes.VERIFY_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
            phoneNoValidationRemaining: 0,
            phoneNoSubmit: true,
            phoneNoLinkSent: true,
            phoneNoValidateError: false,
            isRestartActive: false,
            errorMsg: '',
            phoneNoValidated: true,
            isLoggedIn : true
        },
      };
    }
    case LoginActionTypes.LOGIN_USER: {
      return {
        ...state,
        login: {
          ...state.login,
            phoneNoValidationRemaining: 0,
            phoneNoSubmit: true,
            phoneNoLinkSent: true,
            phoneNoValidateError: false,
            isRestartActive: false,
            errorMsg: '',
            phoneNoValidated: true,
            isLoggedIn : true
        },
      };
    }
    case LoginActionTypes.RESTART_ACTIVE: {
      return {
        ...state,
        login: {
          ...state.login,
            isRestartActive: true,
        },
      };
    }
    case LoginActionTypes.LOGIN_TIMEOUT: {
      return {
        ...state,
        login: {
          ...state.login,
          phoneNoValidateError: true, 
          errorMsg: 'Your request timed out, please try again.'
        },
      };
    }
    default:
      return state;
  }
};