import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import axios from "axios";
import {IUser,IUserState} from "../reducers/user"
import {setRestartActive,setLoginTimeout} from "./login";
import {_baseURL,_env} from "./default.config"
import {store} from '../index';




export enum UserActionTypes {
    VALIDATE_STATUS = "validate_status",
    RESET_USER = "reset_user"
}

export interface IValidateUserAction {
    type: UserActionTypes;
    user: IUser;
  }
 
export type UserActions = IValidateUserAction;

export const resetUser = () =>{
  return async (dispatch: Dispatch) => {
    dispatch({
      user: {},
      type: UserActionTypes.RESET_USER,
    });
  }
} 

export const checkUserValidStatus: ActionCreator<
  ThunkAction<Promise<any>, IUserState, null, IValidateUserAction>
> = () => {
  // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_GET_LOGIN_STATUS;

  const actions = bindActionCreators({setRestartActive, setLoginTimeout}, store.dispatch);

  return async (dispatch: Dispatch) => {
    try {

      const response = fetch(URL);
      response.then(response => {
          return response.json();
        }).then(user => {
          if (user.verified && !store.getState().userState.verified) {

            dispatch({
              user: user,
              type: UserActionTypes.VALIDATE_STATUS,
            });
          }
          else if (!user.verified && store.getState().loginState.login.phoneNoValidationRemaining > 0) {
             if(!store.getState().loginState.login.isRestartActive && store.getState().loginState.login.phoneNoValidationRemaining < 270) {
              actions.setRestartActive()
             }
          }
          else {
            actions.setLoginTimeout()
          }
        });
     
    } catch (err) {
      console.error(err);
    }
  };
};

export interface userSettingsType {
  "full_name": string,
  "email": string,
  "language": string,
  "cell_phone": string,
  "home_phone": string,
  "disable_email_flag": boolean,
  "disable_sms_flag": boolean,
  "send_voice_to_cell_flag": (0 | 1)

}