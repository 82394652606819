import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import MaskedInput from "react-text-mask";
import {Alert, Button, Modal} from "@eb/antd";
// @ts-ignore
import {Redirect, useHistory} from "react-router-dom";
import {pageTitles} from "../actions/default.config";

import { connect,MapDispatchToProps, ConnectedProps} from 'react-redux';
import {decreaseTimerBy1Seconds, sendLoginPhoneNo,resetLoginFlags,loginUser} from "actions/login";
import {checkUserValidStatus} from "actions/user";
import { IAppState } from '../store/store';
import useInterval from "../hooks/useInterval";

// Create the containers interface
interface DispatchProps {
  sendLoginPhoneNo: typeof sendLoginPhoneNo,
  decreaseTimerBy1Seconds : typeof decreaseTimerBy1Seconds
  checkUserValidStatus : typeof checkUserValidStatus
  resetLoginFlags : typeof resetLoginFlags
  loginUser : typeof loginUser
}
interface OwnProps extends RouteComponentProps{}

const LoginPage: React.FunctionComponent<LoginPageProps> = ({...props }) => {
  const history = useHistory();
  const [phNo, setPhNo] = useState<{ raw: string, formatted: string }>({
    raw: '',
    formatted: 'the no. you provided.'
  });

  const {login,user, sendLoginPhoneNo,decreaseTimerBy1Seconds,
    checkUserValidStatus,resetLoginFlags,loginUser } = props;
  
  const [pinging, setPinging] = useState(false);

  const [submitActiveFlag, setSubmitActiveFlag] = useState(false);

  const {t} = useTranslation();

  const isLoginLinkSent: boolean = login.phoneNoLinkSent;
  const isError: boolean = login.phoneNoValidateError;
  const errorMsg: string = login.errorMsg;
  const proceedToHome: boolean =
      login.phoneNoSubmit &&
      login.phoneNoLinkSent &&
      login.phoneNoValidated;


  // Updating Title
  document.title = pageTitles.login;

   // Dynamic delay
   const [delay, setDelay] = useState<number>(4000)
   // ON/OFF
   const [isPolling, setPolling] = useState<boolean>(false)
 
   const [isWaiting, setIsWaiting] = useState(false);
 
   useInterval(() => {
    if (login.phoneNoValidationRemaining > 0) {
     decreaseTimerBy1Seconds();
    }
   }, isWaiting ? 1000 : null)
 
   useInterval(
       () => {
        checkUserValidStatus()
       },
       // Delay in milliseconds or null to stop it
       isPolling ? delay : null,
   )

 
  const usPhnNo = [
    "+",
    /[1]/,
    " ",
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleOnChange = (e: any) => {
    const _ph = e.target.value.replace(/\D+/g, "");
    if (_ph.length >= 11) {
      setPhNo({
        raw: _ph,
        formatted: e.target.value
      })
      setSubmitActiveFlag(true);
    } else {
      setSubmitActiveFlag(false);
    }
  };

  const submitPhoneNo = () => {

    if (phNo.raw.length < 11) {
      setSubmitActiveFlag(false);
      return false;
    }
    // Send to API
    sendLoginPhoneNo(phNo.raw)   
  };

  const pingCheckValidationLink = () => {
    // Send to API
    if (!login.phoneNoValidated) {
      setIsWaiting(true)
      setPolling(true)
    }
  };

  const handleReset = () => {
    setPinging(false);
    resetLoginFlags();
    sendLoginPhoneNo(phNo.raw);   
  }

  const handleLoginRedirect = () => {
    setPinging(false);
    setPolling(false);
    resetLoginFlags();
    setTimeout(() => {
      return history.push(`/login/`);
    }, 0)
  }

  if (isLoginLinkSent && !pinging) {
    pingCheckValidationLink();
    setPinging(true);
  }

  const generateModalButtons = () => {
    let listOfButton: Array<any> = [];
    // Check if restart is active
    if (login.isRestartActive) {
      listOfButton.push(<Button key="back" onClick={handleReset}>
        Didn't get a link? Try Again
      </Button>)
    }

    // Add Cancel button by default
    listOfButton.push(<Button danger key="close" onClick={handleLoginRedirect}> Cancel </Button>)

    return listOfButton;
  }

  useEffect(() => {
    return () => {
      if(user.verified){     
        setPinging(false);
        loginUser();
        setTimeout(() => {
          return history.push(`/settings/user/`);
        }, 0)
      }
    };
  }, [user]);


  return (
      <>
        {proceedToHome ? (
            <Redirect to="/settings/user/"/>
        ) : (
            <div style={{textAlign: "center"}}>
              <h3>Please enter an US Phone no to sign in</h3>
              <div style={styles.inputBox}>
                <MaskedInput
                    mask={usPhnNo}
                    className="form-control"
                    placeholder="Enter a phone number"
                    style={{maxWidth: "188px"}}
                    id="my-input-id"
                    onChange={handleOnChange}
                />
                <br/>

                <Button
                    type="primary"
                    style={styles.submitBtn}
                    disabled={!submitActiveFlag}
                    onClick={submitPhoneNo}
                >
                  Submit
                </Button>

                {/*  Next Step */}
                <Modal
                    title="Validate the Link"
                    visible={isLoginLinkSent}
                    closable={false}
                    footer={generateModalButtons()}
                >
                  <p>{`Please check text message for Link, sent to `}<b>{phNo.formatted}</b></p>
                  <p>{login.phoneNoValidationRemaining}</p>
                  {

                    isError && <Alert
                        message="Error"
                        showIcon
                        description={errorMsg}
                        type="error"
                        // action={
                        //   <Button size="small" onClick={handleLoginRedirect}>
                        //     Retry Login
                        //   </Button>
                        // }
                    />
                  }

                </Modal>
              </div>
            </div>
        )}
      </>
  );
};

const mapStateToProps = (store: IAppState,props: OwnProps) => {
  return {
    login: store.loginState.login,
    user: store.userState.user,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  sendLoginPhoneNo,
  decreaseTimerBy1Seconds,
  checkUserValidStatus,
  resetLoginFlags,
  loginUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginPageProps = ConnectedProps<typeof connector>;

export default connector(LoginPage)



const styles = {
  inputBox: {width: "200px", margin: "0 auto"},
  submitBtn: {
    marginTop: "10px",
  },
};
