import React from "react";
import {Col, Row} from "@eb/antd";
import PubSidebarSearch from "./PS-search-component";

const SearchResultSidebar = (props: any) => {
  return (
      <React.Fragment>
        <Row gutter={[8, 24]}>
          <Col span={24}>
            <PubSidebarSearch/>
          </Col>
        </Row>
      </React.Fragment>
  );
};

export default SearchResultSidebar;
