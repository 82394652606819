import {Dispatch } from 'redux';
import axios from "axios";
import {searchResultType} from '../reducers/common.interface'
import {usStateCodes,_baseURL,_env} from "./default.config";


const listOfUsStateCodes = usStateCodes;

export enum SearchActionTypes {
    FETCH_ALL_PUB = "fetch_all_publication"
}

export interface ISearchAction {
    type: SearchActionTypes.FETCH_ALL_PUB;
    pubs: searchResultType;
    searchString : '';
}

export type SearchAction = ISearchAction;

export const searchByString =(searchString: string = '', cursor: string) => {
    const isValidZip: boolean = /(^\d{5}$)/.test(searchString); // us zip code regex
    const isStateCode: boolean =
        /[^,]*[A-Za-z]{2}/.test(searchString) && !!listOfUsStateCodes[searchString.toUpperCase()]; // us state code regex e.g. CA, AL
    const cursorParam: object = !!cursor ? {cursor: cursor} : {};
    
    // @ts-ignore
    const URL = _baseURL + _env.REACT_APP_API_GET_SEARCH_PUB + searchString;

    if (isValidZip || isStateCode) {
        return async (dispatch: Dispatch) => {
            try{
                const response = await axios.get(URL,{params: cursorParam});
                dispatch({
                    pubs : response.data ,
                    searchString : searchString,
                    type: SearchActionTypes.FETCH_ALL_PUB,
                  })

            }
            catch(err){
                console.log(err)
            }
  };
}
}