import React from "react";
import {useTranslation} from "react-i18next";

const PubSidebarAddress = (props: any) => {
  const {t} = useTranslation();
  const agencyURL = process.env.REACT_APP_CITIZEN_PAGE;
  const agencyUrl: string = props.url;
  const agencyName: string = props.name;
  const agencyAddr = props.addr;
  const city = props.city;
  const state = props.state;
  const zipcode = props.zip;

  return (
    <React.Fragment>
      {!!agencyAddr && (
        <div>
          {/*  Address Section  */}
          <b>{t("address")}</b>
          <br />
          <a  href={`${agencyURL}/${agencyUrl}`}>{agencyName}</a> <br />
          <span>{agencyAddr}</span> <br />
          <span>{`${city}, ${state} ${zipcode}`}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default PubSidebarAddress;
