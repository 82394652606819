import React from "react";
import {Redirect, useParams} from "react-router-dom";
import { IAppState } from '../store/store';
import { connect} from 'react-redux';
import {fetchPubDataByID} from "../actions/alert";


const JumpPage: React.FunctionComponent = (props: any) => {
  // @ts-ignore
  let {tokenId} = useParams();
 
  const initialPubID: string = props.alertData[props.setLang].id;
  const pubData: any = initialPubID && props.alertData[props.setLang];

  React.useEffect(() => {
    props.fetchPubDataByID(tokenId);
  }, []);

  return (
      <>
        {
          pubData ? <Redirect to={`/alert/${pubData.id}`}/> :
              <div style={{textAlign: "center"}}>
                <h3>Please wait while we check your link</h3>
              </div>
        }
      </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    alertData: store.alertState.alert,
    setLang : store.alertState.setLang,
  };
};

const mapDispatchToProps = {
  fetchPubDataByID
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(JumpPage)


