import React from "react";
//import { useTranslation } from "react-i18next";
import {Button, Result} from "@eb/antd";

const NotFoundPage: React.FunctionComponent = () => {
  //const { t } = useTranslation();

  const handleButtonClick = () => {
    //@todo Fix external url link
    // @ts-ignore
    window.location = "//www.nixle.com/consumer-faqs";
  };
  return (
      <div style={{textAlign: "center"}}>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary" onClick={handleButtonClick}>
                Go to Help
              </Button>
            }
        />
      </div>
  );
};

export default NotFoundPage;
