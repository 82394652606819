import React, {useContext, useEffect, useState} from "react";
import {Redirect,RouteComponentProps} from "react-router-dom";
import useInterval from "../hooks/useInterval";
import {pageTitles} from "../actions/default.config";
import { connect,MapDispatchToProps, MapStateToProps,ConnectedProps} from 'react-redux';
import {logoutUser} from "actions/login";
import { IAppState } from '../store/store';

// Create the containers interface
interface DispatchProps {
  logoutUser: typeof logoutUser,
}

interface OwnProps extends RouteComponentProps{}

const LogoutPage: React.FunctionComponent<LogoutPageProps> = ({ login,logoutUser,...props}) => {
 
  const successMsg: string = 'Thank you for Using Nixle.';
  const waitMsg: string = 'Please wait while being logged out';
  const MAX_WAIT_PRE_REDIRECT: number = 5000; // 5 seconds in milliseconds

  const [timerValue, setTimerValue] = useState<number | null>(null);
  const [remainingTime, setRemainingTime] = useState<number>(MAX_WAIT_PRE_REDIRECT / 1000); // Default to MAX_WAIT_PRE_REDIRECT
  const [displayText, setDisplayText] = useState<string>(waitMsg);

  // Updating Title
  document.title = pageTitles.logout

  useInterval(() => {
    // Count down completed
    if (remainingTime <= 0) {
      // Redirect
      // @ts-ignore
      window.location = `${process.env.REACT_APP_APP_BASE_URL}login`;
      // Disable timer
      setTimerValue(null)
    } else {
      setRemainingTime((prevTime: number) => {
        return --prevTime;
      })
    }
  }, timerValue)

  const initRedirectTimer = () => {
    setTimerValue(1000);
  }

  // On logout status changed
  useEffect(() => {
      logoutUser();
      // Logout Success
      setDisplayText(successMsg);
      // Init Redirect timer
      initRedirectTimer()
    
  }, []);


  return (
      <>
        {
              <div style={{textAlign: "center"}}>
                <h3>{
                  displayText
                }</h3>
                {
                  !!timerValue && <h5>{`You will be redirected to login page in ${remainingTime} seconds`}</h5>
                }
              </div>
        }
      </>
  );
};

const mapStateToProps = (store: IAppState,props: OwnProps) => {
  return {
    login: store.loginState.login
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  logoutUser
};

const connector = connect(mapStateToProps,mapDispatchToProps);

type LogoutPageProps = ConnectedProps<typeof connector>;

export default connector(LogoutPage)