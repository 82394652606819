import React, {useContext} from "react";
import {Button, Card, Col, Image, Row, Skeleton, Space, Tabs} from "@eb/antd";
import AlertTag from "./structure/Alert-tag";
import Title from "@eb/antd/es/typography/Title";
import {useHistory, useParams} from 'react-router-dom';
import {searchDirection} from "../actions/default.config";
import {searchResultType} from "../reducers/common.interface";


const {TabPane} = Tabs;

const SearchResultMainContent = (props: any) => {
  const history = useHistory();


  const _searchData: searchResultType = props.payload;
  const _isLoaded: boolean = props.isLoaded;
  const _phImageURL: string = "https://via.placeholder.com/60?text=No+Logo";

  // @ts-ignore
  let {searchString} = useParams();

  const isPrevEnabled: boolean = !!_searchData.previous;
  const isNextEnabled: boolean = !!_searchData.next;

  const regexToExtractCursor: string = '[?&]cursor=([^&#]+)'

  const handleSearchListAlertClick = (pubId: number) => {
    return history.push(`/alert/${pubId}`);
  }


  const handlePaginationChange = (order: searchDirection) => {
    const direction: string = order === searchDirection.prev ? "previous" : "next";
    // @ts-ignore
    const directionURL: string = _searchData[direction];
    if (!directionURL) {
      return false;
    }

    const requestCursor: any = directionURL.match(regexToExtractCursor);
    props.searchByString(searchString, decodeURIComponent(requestCursor[1]));
  };

  return (
      <React.Fragment>
        <Row gutter={[4, 16]}>
          {_isLoaded ? (
              <>
                {_searchData.results.length > 0 ? (
                    <>
                      <Col span={24}>
                        <Title
                            level={3}
                        >{`Result for "${_searchData.searchString}"`}</Title>
                      </Col>
                      <Col span={24}>
                        <Tabs defaultActiveKey="1" type="card">
                          <TabPane tab="Pub" key="1">
                            {_searchData.results.map((_r) => {
                              const imgPath = _r.agency.logo || _phImageURL;
                              return (
                                  <Card style={styles.card} key={_r.id}
                                        onClick={() => handleSearchListAlertClick(_r.id)} hoverable>
                                    <Row gutter={[12, 4]}>
                                      <Col flex="60px">
                                        <Image src={imgPath} preview={false}/>
                                      </Col>
                                      <Col flex="auto">
                                        <AlertTag type={_r.pub_type}/>
                                        <Title style={styles.titleStyle} level={4}>
                                          {_r.agency.name}
                                        </Title>
                                        <div>
                                          <i>{_r.published_str}</i>
                                        </div>
                                        <div style={styles.headline}>{_r.headline}</div>
                                      </Col>
                                    </Row>
                                  </Card>
                              );
                            })}
                            <Space>
                              <Button disabled={!isPrevEnabled} onClick={() => {
                                handlePaginationChange(searchDirection.prev)
                              }}>Prev</Button>
                              <Button disabled={!isNextEnabled} onClick={() => {
                                handlePaginationChange(searchDirection.next)
                              }}>Next</Button>
                            </Space>
                          </TabPane>
                        </Tabs>
                      </Col>
                    </>
                ) : (
                    <Col span={24}>
                      <div>No Results found!</div>
                    </Col>
                )}
              </>
          ) : (
              <h2>Please search for Publications by zip code</h2>
          )}
        </Row>
      </React.Fragment>
  );
};

export default SearchResultMainContent;

const styles: any = {
  card: {
    width: "96%",
    borderWidth: "0px 0px 1px 0px",
    cursor: "pointer",
    margin: "2%"
  },
  titleStyle: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  headline: {
    marginTop: "10px",
  },
};
