import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import App from "./root/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
// Store type from Redux
import { Store } from 'redux';

// Import the store function and state
import configureStore, { IAppState } from './store/store';

interface IProps {
    store: Store<IAppState>;
  }
export const {store,persistor} = configureStore();

ReactDOM.render(
<Provider store={store}> <PersistGate loading={null} persistor={persistor}><App/> </PersistGate></Provider>
, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
