import React, {useEffect} from "react";

function FacebookCommentsPluginV2() {
  const postUrl: string = window.location.href; // Active Page URL to share in Facebook post
  const noOfComments: number = 10; // No of facebook Comments to display
  const dataWidth: string = "100%"; // Width for the Facebook comments plugins

  useEffect(() => {
    try {
      // @ts-ignore
      window.facebookInit();
    } catch (e) {
      // @todo Handle Facebook Comments Plugin Fetch error.
    }
  }, []);

  return (
    <div
      className="row fb-comments px-2"
      data-href={postUrl}
      data-width={dataWidth}
      data-numposts={noOfComments}
    >
      {/*  Intentionally left empty as a fb iframe render area  */}
    </div>
  );
}

export default FacebookCommentsPluginV2;
