import React, {createRef, useRef, useState} from "react";
import {Col, Image, Modal, Row, Tooltip} from "@eb/antd";
import {FilePdfOutlined, FileWordOutlined} from "@ant-design/icons";
import DocViewer, {PDFRenderer} from "react-doc-viewer";
import {useTranslation} from "react-i18next";
import './Media-loader-component.css';

const styles = {
  imageBox: {
    width: '150px',
    height: '150px',
    display: 'inline-block',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    cursor: 'pointer'
  }
}

const RenderPdf = (props: any) => {
  const {m, toOpenFile} = props;
  const {t} = useTranslation();
  // pdf old color code F75421
  return (
    <Col key={m.media}>
      <Tooltip title={m.media_md}>
        <div
          onClick={(e) => {
            toOpenFile(m.media);
          }}
          style={{
            backgroundColor: "#3FA0E8",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
            width: "150px",
            height: "150px",
          }}
        >
          <Row align="middle" gutter={[8, 16]} justify="space-around">
            <Col xs={24}>
              <FilePdfOutlined style={{ fontSize: "50px", color: "#fff" }} />
            </Col>
            {/*<Col>{m.media_md}</Col>*/}
          </Row>
        </div>
      </Tooltip>
    </Col>
  );
};

const RenderDoc = (props: any) => {
  const {m, toOpenFile} = props;
  const {t} = useTranslation();
  return (
    <Col key={m.media}>
      <Tooltip title={m.media_md}>
        <div
          onClick={(e) => {
            toOpenFile(m.media);
          }}
          style={{
            backgroundColor: "#3FA0E8",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
            width: "150px",
            height: "150px",
          }}
        >
          <Row align="middle" gutter={[8, 16]} justify="space-around">
            <Col xs={24}>
              <FileWordOutlined style={{ fontSize: "50px", color: "#fff" }} />
            </Col>
            {/*<Col>{m.media_md}</Col>*/}
          </Row>
        </div>
      </Tooltip>
    </Col>
  );
};

const renderMediaType = (
    m: any,
    openPdfFile: Function,
    openWordFile: Function
) => {
  const typeForm = m?.type;
  switch (typeForm) {
    case "pdf":
      return <RenderPdf m={m} toOpenFile={openPdfFile} key={m.media}/>;
    case "doc":
      return <RenderDoc m={m} toOpenFile={openWordFile} key={m.media}/>;
  }
};

const MediaLoader = (props: any) => {
  const mediaArr = props.media;
  const imgMediaArr = mediaArr.filter((media: any) => {
    return media.type === "img";
  });
  const fileMediaArr = mediaArr.filter((media: any) => {
    return media.type !== "img";
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docs, setDocs] = useState([]);

  // @todo: follow the antui issue below to resolve the modal
  // https://github.com/ant-design/ant-design/issues/26136
  const handlePDFLoad = (f: string) => {
    // @ts-ignore
    setDocs([{uri: f}]);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 0);
  };
  const handleWordLoad = (f: string) => {
    // @ts-ignore
    setDocs([{uri: f}]);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 0);
    //setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderImage = (m: any, i: number) => {
    //  @todo fix alt text with proper reference
    const overrideImgStyle = {...styles.imageBox, backgroundImage: `url('${m.media}')`}
    return (
        <Col  key={m.media}>
          <div style={overrideImgStyle}>
           <a href="#"> <Image style={{visibility: 'hidden'}} src={m.media_md} alt={"Attachement "+(i+1)} preview={{ src: `${m.media}` }} /></a>
          </div>
        </Col>
    );
  };

  return (
      <React.Fragment>
        <Row align="top" gutter={[16, 16]}>
          {imgMediaArr &&
          imgMediaArr.map((mediaElm: any, i: number) => {
            return renderImage(mediaElm, i);
          })}
          {fileMediaArr &&
          fileMediaArr.map((mediaElm: any) => {
            return renderMediaType(mediaElm, handlePDFLoad, handleWordLoad);
          })}
        </Row>
        {isModalVisible && (
            <Modal
                title="Document Viewer"
                visible={isModalVisible}
                onCancel={handleCancel}
            >
              <DocViewer
                  pluginRenderers={[PDFRenderer]}
                  documents={docs}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                      retainURLParams: false,
                    },
                  }}
              />
            </Modal>
        )}
      </React.Fragment>
  );
};

export default MediaLoader;
