import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";
import {IAgency,IAgencyState,IAgencyPubs} from "../reducers/agency"
import {_baseURL,_env} from "./default.config"


export enum AgencyActionTypes {
  FETCH_DETAIL = "fetch_detail",
  FETCH_PUBS = "fetch_publications",
  NOT_FOUND = "result_not_found"
}

export interface IAgencyAction {
  type: AgencyActionTypes;
  details : IAgency,
  pubsByAgency : IAgencyPubs,
  searchString : '',
}

export type AgencyActions = IAgencyAction;

export type agencyDetails = IAgency;

export type agencyPubs = IAgencyPubs;

export const  fetchAgencyPubByID :ActionCreator<
ThunkAction<Promise<any>, IAgencyState, null, IAgencyAction>
> = (agencyID: number, cursor: string = '') => {

  const cursorParam: object = !!cursor ? {cursor: cursor} : {};
   // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_GET_AGENCY_BY_ID_NAME + agencyID+ '/pubs';
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(URL,{params: cursorParam})
          dispatch({
            pubsByAgency: response.data,
            type: AgencyActionTypes.FETCH_PUBS,
        });
      }
       catch (err) {
        return async (dispatch: Dispatch) => {
          dispatch({
            type: AgencyActionTypes.NOT_FOUND,
        });
        }
      }
    }
}

export const getAgencyDetails: ActionCreator<
  ThunkAction<Promise<any>, IAgencyState, null, IAgencyAction>
> = (agencyName:string, agencyID: number) => {

        // @ts-ignore
    const URL = _baseURL + _env.REACT_APP_API_GET_AGENCY_BY_ID_NAME + agencyName;
    const isValidAgencyID: boolean = /(^\d{6}$)/.test(agencyName); // agency id regex
    const isValidAgencyName: boolean = /(^[a-zA-Z\d\-]+$)/.test(agencyName); // agency url name

    if (isValidAgencyID || isValidAgencyName) {
    return async (dispatch: Dispatch) => {
        try {
          const response = await axios.get(URL)
            dispatch({
              details : response.data ,
              searchString : agencyName,
              type: AgencyActionTypes.FETCH_DETAIL,
            })
            
            // @ts-ignore
            const URL_P = _baseURL + _env.REACT_APP_API_GET_AGENCY_BY_ID_NAME + response.data.id + '/pubs';
           

            //chaining action
            axios.get(URL_P).then(resp => resp.data)
            .then(data =>{
             
              dispatch({
                pubsByAgency: data,
                type: AgencyActionTypes.FETCH_PUBS,
            });
            })
           
        } catch (err) {
          return async (dispatch: Dispatch) => {
            dispatch({
              type: AgencyActionTypes.NOT_FOUND,
          });
          }
        }
      };
    }
    else {
      return async (dispatch: Dispatch) => {
        dispatch({
          type: AgencyActionTypes.NOT_FOUND,
      });
      }
    }
}
