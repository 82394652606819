import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {pageTitles} from "../actions/default.config";
import { connect,ConnectedProps} from 'react-redux';
import { IAppState } from "store/store";

const HomePage: React.FunctionComponent<HomePageProps> = ({user,...props}) => {
  const {t} = useTranslation();
 

  const {first_name, last_name} = user;

  // Updating Title
  document.title = pageTitles.home


  return (
      <div style={{textAlign: "center"}}>
        <h3>Welcome to Homepage</h3>
        <h4>{`You are now logged in on all devices, ${first_name} ${last_name}`}</h4>
      </div>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    user: store.userState.user,
  };
};

const connector = connect(mapStateToProps);

type HomePageProps = ConnectedProps<typeof connector>;

export default connector(HomePage)


