 
 import {stateCodesType} from "../reducers/common.interface"

  export const translationSeperator = "||||";

  export const availableLanguages = {
    en: "English",
    es: "Spanish",
  };
  
  export enum pageTitles {
    login = 'Everbridge Nixle : Login',
    logout = 'Everbridge Nixle : Logout',
    home = 'Everbridge Nixle : Home',
    validate = 'Everbridge Nixle',
    pageNotFound = 'Everbridge Nixle : Page Not Found',
    pageError = 'Everbridge Nixle : Error Page',
    searchPublication = 'Everbridge Nixle : Search',
    searchAgency = 'Everbridge Nixle : Search',
    alert = 'Everbridge Nixle : ',
    agency = 'Everbridge Nixle : ',
    settings = 'Everbridge Nixle : Settings',
    account = 'Everbridge Nixle : Account'
  }
  
  
  export const usStateCodes : stateCodesType = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };
  
  
  export enum LanguageSet {
    EN = "en", // Default
    ES = "es",
  }

export enum searchDirection {
  next,
  prev
}

export const _env = process.env;

export const _baseURL: string | undefined = _env.REACT_APP_API_BASE_URL;

export const _appURL: string | undefined = _env.REACT_APP_APP_BASE_URL;

const _loginURL : string   = _env.REACT_APP_LOGIN_URL || '';

const _citizenURL : string | undefined = _env.REACT_APP_CITIZEN_PAGE

function rtrim(s:any ) { 
  return s.replace(new RegExp('/' + "*$"),''); 
};

export const navLinks: {
  linkName: string;
  linkTo: string;
  type: "nav" | "side";
  isPrivate: boolean;
  isExternal?: boolean;
}[] = [
  {
    linkName: "Login",
    linkTo: _loginURL,// temporary link to old site
    type: "nav",
    isExternal: true,
    isPrivate: false,
  },
  {
    linkName: "Setting",
    linkTo: "/settings/user",
    type: "side",
    isExternal: false,
    isPrivate: true,
  },
  {
    linkName: "Account",
    linkTo: "/#",
    type: "side",
    isExternal: false,
    isPrivate: true,
  },
  {
    linkName: "Logout",
    linkTo: "/logout",
    type: "nav",
    isExternal: false,
    isPrivate: true,
  },
  {
    linkName: "Sign up",
    linkTo: rtrim(_citizenURL)+"/register/",
    isExternal: true,
    type: "nav",
    isPrivate: false,
  }
];