import { Reducer } from 'redux';
import { UserSettingActions, UserSettingActionTypes} from "../actions/user-settings";
import {availableLanguages} from "../actions/default.config"
export interface  IUserSetting {
    "full_name": string,
    "email": string,
    "language": typeof availableLanguages,
    "cell_phone": string,
    "home_phone": string,
    "disable_email_flag": boolean,
    "disable_sms_flag": boolean,
    "send_voice_to_cell_flag": number,
    "error" : any
}
export interface  IUserSubscription {
    "id": number,
    "agency_id": number,
    "name": string,
    "urlname":string,
    "is_default": boolean,
    "is_national": boolean,
    "is_private": boolean,
    "subscribe_flag": boolean,
    "email_level": number,
    "sms_level": number,
    "voice_choice": number,
    "sub_type": number,
    "category_id": number
}

export interface  IUserLocation {
    "id": number,
    "location_name": string,
    "address": string,
    "cross_street": string,
    "city": string,
    "state": string,
    "zipcode": string,
    "latitude": string,
    "longitude":string,
    "created": string,
    "modified": string,
    "sub_type": number,
    "region_id": number,
    "subscribe_default": boolean,
    "primary_flag": boolean,
    "active_flag": boolean,
    "disable_email_flag": boolean,
    "disable_sms_flag": boolean,
    "subscriptions": IUserSubscription[]
}

export interface IUserAllSettingState {
    settings : {
        user ?: IUserSetting,
        locations ?: IUserLocation
    }
}

  // Define the login State
  export interface IUserSettingState {
    readonly userSetting:  IUserAllSettingState;
  }

    // Define the initial state
const initialUserSettingState: IUserSettingState = {
    userSetting : <IUserAllSettingState> {}
  };

  export const userSettingReducer: Reducer<IUserSettingState,UserSettingActions> = (
    state = initialUserSettingState,
    action: any
  ) => {
    switch (action.type) {
        case UserSettingActionTypes.GET_SETTING: {
            return {
              ...state,
              userSetting :{
                ...state.userSetting,
               settings : {
                    ...state.userSetting.settings,
                    user : {...action.setting.user,error : "" }
               }
              }
            };
          }
          case UserSettingActionTypes.GET_LOCATIONS: {
            return {
              ...state,
              userSetting :{
                ...state.userSetting,
                settings : {
                    ...state.userSetting.settings,
                    locations : action.setting.locations
                }
              }
            };
          }
          case UserSettingActionTypes.SET_ERROR: {
            return {
              ...state,
              userSetting :{
                ...state.userSetting,
                settings : {
                  ...state.userSetting.settings,
                    user : { ...state.userSetting.settings.user,error:action.setting.user.error}
                }
              }
            };
          }
        default:
            return state;
    }
  }