import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import * as REGISTRY from './pages-registry';
import {useTranslation} from "react-i18next";

import "../i18n/config";
import "./App.scss";
import {LayoutPage} from "./pages-registry";
import PrivateRoute from "./private-route";

function App() {
  const {t} = useTranslation();

  return (
      <div className="App" style={{height: "100vh"}}>
        <Router>
          <LayoutPage>
            <Switch>
              {/* Public Routes */}
                <Route exact path="/" component={REGISTRY.RedirectToEBPage}/>
                <Route exact path="/login/" component={REGISTRY.LoginPage}/>
                <Route exact path="/logout/" component={REGISTRY.LogoutPage}/>
                <Route exact path="/validate/:tokenId" component={REGISTRY.VerifyPage}/>
                <Route exact path="/404/" component={REGISTRY.NotFoundPage}/>
                <Route exact path="/500/" component={REGISTRY.ErrorPage}/>
                <Route exact path="/redirect/alert/:tokenId" component={REGISTRY.JumpPage}/>
                <Route exact path="/alert/:pubId" component={REGISTRY.PublicationPage}/>
                <Route exact path="/search/publication/" component={REGISTRY.ResultPage}/>
                <Route exact path="/search/publication/:searchString" component={REGISTRY.ResultPage}/>
                <Route exact path="/email_forward_alert/:pubId" component={REGISTRY.ResultPage}/>
                <PrivateRoute path="/home/" component={REGISTRY.HomePage}/>
                {/*  IMPORTANT: Home Route Should be Above the Agency Name Route */}
                <Route exact path="/:agencyName" component={REGISTRY.AgencyPage}/>
                {/*  Private Routes */}
                
                <PrivateRoute path="/settings/user" component={REGISTRY.SettingPage} exact />
          
                {/* <PrivateRoute path="/settings/account/" component={REGISTRY.AccountPage}/> */}
                <Route component={REGISTRY.NotFoundPage}/>
            </Switch>
          </LayoutPage>
        </Router>
      </div>
  );
}

export default App;
