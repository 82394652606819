import { Reducer } from 'redux';
import { SearchAction, SearchActionTypes} from "../actions/search";
import {searchResultType} from "./common.interface";

    
export interface ISearchState {
  readonly pubs: searchResultType
}

// Define the initial state
const initialLoginState: ISearchState = {
 pubs: <searchResultType>{}
}

  export const searchReducer: Reducer<ISearchState, SearchAction> = (
    state = initialLoginState,
    action
  ) => {
    switch (action.type) {
        case SearchActionTypes.FETCH_ALL_PUB: {
            return {
              ...state,
              pubs :{
                ...state.pubs,
                next : action.pubs.next,
                previous : action.pubs.previous,
                results : action.pubs.results,
                searchString: action.searchString
              }
            };
          }
        default:
            return state;
    }
  };