import React from "react";
import {agencyDetails, agencyPubs} from "../actions/agency";

import {Button, Card, Col, Image, Row, Skeleton, Space, Tabs} from "@eb/antd";
import AlertTag from "./structure/Alert-tag";
import {useHistory, useParams} from 'react-router-dom';
import {searchDirection} from "../actions/default.config";
import PubSidebarAddress from "./PS-address-component";
import PubSidebarContact from "./PS-contact-component";
import {FacebookOutlined, LinkOutlined, MailOutlined, TwitterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const AgencyPageMainContent = (props: any) => {
  const history = useHistory();
  const {t} = useTranslation();

  const _pubsData: agencyPubs = props.pubs;
  const _agencyDetails: agencyDetails = props.agencyDetails
  const _isLoaded: boolean = props.isLoaded;
  const agencyURL = process.env.REACT_APP_CITIZEN_PAGE;

  // Remove double slash from the APP base URL, to resolve facebook link error.
  const activeHrefURL: string = `${process.env.REACT_APP_APP_BASE_URL}`.replaceAll('//', '');
  const forwardEmail: string = `${process.env.REACT_APP_CITIZEN_PAGE}/email_forward_agency/${_agencyDetails?.id}`;
  const fbShareURL: string = `${process.env.REACT_APP_FB_SHARE_URL}${activeHrefURL}${_agencyDetails?.urlname}`;
  const twitterShareURL: string = `${process.env.REACT_APP_TWITTER_SHARE_URL}${activeHrefURL}${_agencyDetails?.urlname}`;

  // @ts-ignore
  let {searchString} = useParams();

  const isPrevEnabled: boolean = !!_pubsData.previous;
  const isNextEnabled: boolean = !!_pubsData.next;

  const regexToExtractCursor: string = '[?&]cursor=([^&#]+)'

  const handleSearchListAlertClick = (pubId: number) => {
    return history.push(`/alert/${pubId}`);
  }


  const handlePaginationChange = (order: searchDirection) => {
    const direction: string = order === searchDirection.prev ? "previous" : "next";
    // @ts-ignore
    const directionURL: string = _pubsData[direction];
    if (!directionURL) {
      return false;
    }

    const requestCursor: any = directionURL.match(regexToExtractCursor);
    props.fetchAgencyPubByID(_agencyDetails.id, decodeURIComponent(requestCursor[1]));
  };

  return (
      <React.Fragment>
        <Row gutter={[4, 16]}>
          {_isLoaded ? (
              <>

                {
                  !!_agencyDetails?.urlname ?
                      <>
                        <Col span={4}>
                          {_agencyDetails?.logo ? (
                              <a href={`${agencyURL}/${_agencyDetails?.urlname}`}>
                                <Image src={_agencyDetails?.logo} preview={false}/>
                              </a>
                          ) : (
                              <Space>
                                <Skeleton.Image style={{width: 103}}/>
                              </Space>
                          )}
                        </Col>
                        <Col span={20}>
                          {_agencyDetails?.name ? (
                              <a href={`${agencyURL}/${_agencyDetails?.urlname}`}>
                                <h2>{_agencyDetails?.name}</h2>
                              </a>
                          ) : (
                              <Space>
                                <Skeleton.Input
                                    style={{width: 280}}
                                    active={true}
                                    size="default"
                                />
                              </Space>
                          )}
                        </Col>
                        <Col span={24}>

                          <PubSidebarAddress
                              url={_agencyDetails?.urlname}
                              addr={_agencyDetails?.addr}
                              name={_agencyDetails?.name}
                              city={_agencyDetails?.city}
                              state={_agencyDetails?.state}
                              zip={_agencyDetails?.zipcode}
                          />
                        </Col>
                        <Col span={24}>
                          <PubSidebarContact phone={_agencyDetails?.phone}/>
                        </Col>

                        <Col span={24}>
                          {/* Social Media Share Section */}
                          <a
                              href={fbShareURL}
                              target="_blank"
                              rel="noreferrer"
                              title={t("share_to_facebook")}
                              style={{marginRight: "15px"}}
                          >
                            <FacebookOutlined
                                style={{fontSize: "36px", color: "#3B5998"}}
                            />
                          </a>
                          <a
                              href={twitterShareURL}
                              target="_blank"
                              rel="noreferrer"
                              title={t("share_to_twitter")}
                              style={{marginRight: "15px"}}
                          >
                            <TwitterOutlined style={{fontSize: "36px", color: "#55ACEE"}}/>
                          </a>
                          <a href="#0" title={t("copy_link")} style={{marginRight: "15px"}}>
                            <LinkOutlined style={{fontSize: "36px", color: "#243842"}}/>
                          </a>
                          <a
                              href={forwardEmail}
                              target="_blank"
                              rel="noreferrer"
                              title={t("forward_by_email")}
                          >
                            <MailOutlined style={{fontSize: "36px", color: "#0166FF"}}/>
                          </a>
                          {/* Ends Social Media Share Section */}
                        </Col>

                        {_pubsData.results.length > 0 ? (
                            <>
                              <Col span={24}>
                                {_pubsData.results.map((_r) => {
                                  return (
                                      <Card style={styles.card} key={_r.id}
                                            onClick={() => handleSearchListAlertClick(_r.id)} hoverable>
                                        <Row gutter={[12, 4]}>
                                          <Col flex="auto">
                                            <AlertTag type={_r.pub_type}/>
                                            <div style={styles.published}>
                                              <i>{_r.published_str}</i>
                                            </div>
                                            <div style={styles.headline}>{_r.headline}</div>
                                          </Col>
                                        </Row>
                                      </Card>
                                  );
                                })}
                                <Space>
                                  <Button disabled={!isPrevEnabled} onClick={() => {
                                    handlePaginationChange(searchDirection.prev)
                                  }}>Prev</Button>
                                  <Button disabled={!isNextEnabled} onClick={() => {
                                    handlePaginationChange(searchDirection.next)
                                  }}>Next</Button>
                                </Space>
                              </Col>
                            </>
                        ) : (
                            <Col span={24}>
                              <div>No Publication found!</div>
                            </Col>
                        )}

                      </> : (
                          <Col span={24}>
                            <div>No Agency Details found!</div>
                          </Col>
                      )
                }

              </>
          ) : (
              <Skeleton active={true} round={false}/>
          )}
        </Row>
      </React.Fragment>
  );
};

export default AgencyPageMainContent;

const styles: any = {
  card: {
    width: "96%",
    borderWidth: "0px 0px 1px 0px",
    cursor: "pointer",
    margin: "2% 0px"
  },
  titleStyle: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  published: {
    marginTop: "10px",
    fontSize: "13px"
  },
  headline: {
    fontWeight: 'bold'
  }
};
