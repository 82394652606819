import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {IApplicationState} from "../reducers/application"


export enum ApplicationActionTypes {
    UPDATE_DIMENSION = "update_dimension",
    LOADING = "enable_loading",
    LOADED = "loding_done"
}

export interface IApplicationAction {
    type: ApplicationActionTypes;
}

export const updateDimensions:ActionCreator<
ThunkAction<Promise<any>, IApplicationState, null, IApplicationAction>
>  = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ApplicationActionTypes.UPDATE_DIMENSION
        })
    }
}

export const toggleLoaderBar = (status : boolean) => {
    if(status){
            return async (dispatch: Dispatch) => {
                dispatch({
                    type: ApplicationActionTypes.LOADING
                })
            }
    } else {
            return async (dispatch: Dispatch) => {
                dispatch({
                    type: ApplicationActionTypes.LOADED
                })
            }
    }
}

export type ApplicationAction = IApplicationAction;
