import React, {useState} from "react";
import SearchResultSidebar from "../components/Result-sidebar-component";
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import {useLocation, useParams} from "react-router-dom";
import {Col, Row} from "@eb/antd";
import AgencyPageMainContent from "../components/Agency-maincontent-component";
import {pageTitles} from "../actions/default.config";
import { connect,ConnectedProps,MapDispatchToProps} from 'react-redux';
import {getAgencyDetails,fetchAgencyPubByID} from "../actions/agency";
import { IAppState } from '../store/store';

// Create the containers interface
interface DispatchProps {
  getAgencyDetails : typeof getAgencyDetails 
  fetchAgencyPubByID : typeof fetchAgencyPubByID
}
interface OwnProps extends RouteComponentProps{}

const AgencyPage: React.FunctionComponent<AgencyPageProps> =  ({...props}) => {

  const [pathName, setPathName] = useState('');
  const location = useLocation()

  const {agencyData,getAgencyDetails,fetchAgencyPubByID} = props;
  // @ts-ignore
  let {agencyName = ''} = useParams();

  React.useEffect(() => {
    // Updating Title
    document.title = pageTitles.agency + agencyData.details.name

  }, [agencyData])

  React.useEffect(() => {
    if (pathName !== location.pathname) {
      setPathName(location.pathname);
      getAgencyDetails(agencyName, null);
   
    }
  }, [location])

  return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={24} md={16}>
            <AgencyPageMainContent
                agencyDetails={agencyData.details}
                pubs={agencyData.pubsByAgency}
                isLoaded={agencyData.searchString === agencyName}
                fetchAgencyPubByID ={fetchAgencyPubByID}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <SearchResultSidebar/>
          </Col>
        </Row>
      </React.Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    agencyData: store.agencyState.agency,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  getAgencyDetails,
  fetchAgencyPubByID
};

const connector = connect(mapStateToProps,mapDispatchToProps);

type AgencyPageProps = ConnectedProps<typeof connector>;

export default connector(AgencyPage)
