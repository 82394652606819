import React, {useContext} from "react";
import {availableLanguages,LanguageSet} from "../actions/default.config";
import {useTranslation} from "react-i18next";
// import { Grid, Select, FormControl } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import PubSidebarContact from "../components/PS-contact-component";
import PubSidebarAddress from "../components/PS-address-component";
import PubSidebarTextATip from "../components/PS-textatip-component";
import {Col, Row, Select} from "@eb/antd";
import {
  FacebookOutlined,
  LinkOutlined,
  MailOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import PubSidebarSearch from "./PS-search-component";


const PublicationSidebar = (props: any) => {
  //const classes = useStyles();

  const {t} = useTranslation();

  const _selectedLang: LanguageSet = props.setLang;
  const _pubData: any = props.pubdata ? props.pubdata[_selectedLang]  : null;
  const isActive: boolean = !_pubData?.is_deactivated && !_pubData?.is_expired;
  const twitterText: string = 'Test%20for%20QA%20Media%20Email%20Subj+';
  // Remove double slash from the APP base URL, to resolve facebook link error. 
  const activeHrefURL: string = `${process.env.REACT_APP_APP_BASE_URL}`.replaceAll('//', '');
  //@todo Fix the Static english & spanish text
  const tippingURL: string = `${process.env.REACT_APP_CITIZEN_PAGE}/tip/alert/${_pubData?.id}`;
  const forwardEmail: string = `${process.env.REACT_APP_CITIZEN_PAGE}/email_forward_alert/${_pubData?.id}`;
  const fbShareURL: string = `${process.env.REACT_APP_FB_SHARE_URL}${activeHrefURL}alert/${_pubData?.id}`;
  const twitterShareURL: string = `${process.env.REACT_APP_TWITTER_SHARE_URL}${twitterText}${activeHrefURL}alert/${_pubData?.id}`;

  const handleChange = (value: LanguageSet) => {
    props.getTranslatedText(value)
  };

  const langOptions = Object.keys(availableLanguages).map((key: any, idx) => {
    return (
        <option value={key} key={idx}>
          {
            // @ts-ignore
            availableLanguages[key]
          }
        </option>
    );
  });

  return (
      <React.Fragment>
        <Row gutter={[8, 24]}>
          {isActive && (
              <Col span={24} style={{marginBottom: "15px"}}>
                <PubSidebarSearch/>
              </Col>
          )}
          <Col span={24}>
            {/*  Language Selection */}
            <b>{`${t("language")} : `}</b>
            <Select key={_selectedLang}
                defaultValue={_selectedLang}
                style={{width: 120}}
                onChange={handleChange}
                loading={props.enableLangLoading}
            >
              {langOptions}
            </Select>
          </Col>
          {isActive && (
              <Col span={24}>
                <PubSidebarAddress
                    url={_pubData?.agency?.urlname}
                    addr={_pubData?.agency?.addr}
                    name={_pubData?.agency?.name}
                    city={_pubData?.agency?.city}
                    state={_pubData?.agency?.state}
                    zip={_pubData?.agency?.zipcode}
                />
              </Col>
          )}
          {isActive && (
              <Col span={24}>
                <PubSidebarContact phone={_pubData?.agency?.phone}/>
              </Col>
          )}
          {isActive && _pubData?.id && (
              <Col span={24}>
                {/* Social Media Share Section */}
                <a
                    href={fbShareURL}
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                    title={t("share_to_facebook")}
                    style={{marginRight: "15px"}}
                >
                  <FacebookOutlined alt="Send to Facebook"
                                    style={{fontSize: "36px", color: "#3B5998"}}
                  />
                </a>
                <a
                    href={twitterShareURL}
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                    title={t("share_to_twitter")}
                    style={{marginRight: "15px"}}
                >
                  <TwitterOutlined style={{fontSize: "36px", color: "#55ACEE"}}/>
                </a>
                <a className="social-icon" href="#0" title={t("copy_link")} style={{marginRight: "15px"}}>
                  <LinkOutlined style={{fontSize: "36px", color: "#243842"}}/>
                </a>
                <a
                    href={forwardEmail}
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                    title={t("forward_by_email")}
                >
                  <MailOutlined style={{fontSize: "36px", color: "#0166FF"}}/>
                </a>
                {/* Ends Social Media Share Section */}
              </Col>
          )}
          {isActive && (
              <Col span={24}>
                <PubSidebarTextATip
                    keyword={_pubData?.agency?.tipping_keyword}
                    isActive={_pubData?.agency?.has_tipping}
                    tippingUrl={tippingURL}
                />
              </Col>
          )}


        </Row>
      </React.Fragment>
  );
};

export default PublicationSidebar;