import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import { IAppState } from '../store/store';
import PropTypes from 'prop-types';
import { connect} from 'react-redux';

// @ts-ignore
const PrivateRoute = ({component: Component, ...rest}) => {

  const {login} = rest;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(login.isLoggedIn);

  useEffect(() => {
    return () => {
      setIsUserLoggedIn(login.isLoggedIn)
    };
  }, [login.isLoggedIn]);

  return (
      <Route
          {...rest}
          render={(props) => isUserLoggedIn
              ? <Component {...props} />
              : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>}
      />
  )
}

PrivateRoute.propTypes = {
  login: PropTypes.bool.isRequired,
};

function mapStateToProps(store: IAppState) {
  return {
    login: store.loginState.login
  };
}

export default connect(mapStateToProps)(PrivateRoute);