import React from "react";
//import { useTranslation } from "react-i18next";
import {Button, Result} from "@eb/antd";

const RedirectToEBPage: React.FunctionComponent = () => {
  //const { t } = useTranslation();

  const onLoad = () => {
    //@todo Fix external url link
    // @ts-ignore
    window.location = "//www.everbridge.com";
  };

  // Redirect on Load
  onLoad();

  return (
      <div style={{textAlign: "center"}}>

      </div>
  );
};

export default RedirectToEBPage;