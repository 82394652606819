
import { Reducer } from 'redux';
import { ApplicationAction, ApplicationActionTypes} from "../actions/application";

export interface IApplication {
    enableLoading: boolean;
    enableLangLoading: boolean;
    enableSideMenu: boolean;
    windowWidth: number;
    windowHeight: number;
}

export interface IApplicationState {
    readonly application: IApplication
}
  
// Define the initial state
const initialLoginState: IApplicationState = {
    application : {
        enableLoading: true,
        enableLangLoading: false,
        enableSideMenu: false,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
    }
}

export const applicationReducer: Reducer<IApplicationState, ApplicationAction> = (
    state = initialLoginState,
    action
  ) => {
    switch (action.type) {
    case ApplicationActionTypes.UPDATE_DIMENSION: {
        return {
            ...state,
            application : {
                ...state.application,
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            }
        }
    }
    case ApplicationActionTypes.LOADING: {
        return {
            ...state,
            application : {
                ...state.application,
                enableLoading: true,
            }
        }
    }
    case ApplicationActionTypes.LOADED: {
        return {
            ...state,
            application : {
                ...state.application,
                enableLoading: false,
            }
        }
    }
    default:
        return state;
    }
  }