import { Reducer } from 'redux';
import { AlertAction, AlertActionTypes} from "../actions/alert";
import {LanguageSet} from "../actions/default.config";

export interface IAgency {
    "id": null | number;
    "name": string;
    "urlname": string;
    "phone": string;
    "phone2": string;
    "addr": string;
    "suite": string;
    "city": string;
    "state": string;
    "zipcode": string;
    "latitude": null | number;
    "longitude": null | number;
    "has_tipping": boolean;
    "tipping_keyword": string;
    "facebook_comments_enabled": boolean;
    "logo": string;
    "logo_sm": string;
    "logo_md": string;
    "logo_lg": string;
  }

  export interface IAlert {
    "agency" : IAgency,
    "id" :number,
    "headline" : string,
    "pub_text" :string,
    "pub_type" : number,
    "parent_pub" : number | null,
    "created" : string,
    "modified" : string,
    "expires" : string,
    "published_time" : string,
    "published_str" : string ,
    "include_incident" : boolean,
    "incident_addr" : string,
    "incident_city" : string ,
    "incident_state" : string,
    "incident_zipcode" : string,
    "incident_lat" : string,
    "incident_long" : string,
    "incident_entered" : string,
    "incident_geocoded" :string,
    "bc_addr_type" : number,
    "bc_addr" : string,
    "bc_cross_st" : string,
    "bc_city" : string,
    "bc_state" : string,
    "bc_zipcode" :string,
    "bc_latitude" : string,
    "bc_longitude" :string,
    "bc_radius" : number,
    "contact_name" : string,
    "contact_division" : string,
    "contact_email" : string,
    "contact_phone" : string,
    "cap_severity" : string,
    "cap_urgency" : string,
    "cap_certainty" : string,
    "cap_category" : string,
    "cap_event" : string,
    "cap_instructions" : string,
    "is_html" : boolean,
    "is_test" :boolean,
    "is_expired" : boolean,
    "is_deactivated" :boolean,
    "media" : mediaBlockType,
    "severity_string" : string | null,
    "urgency_string" : string | null,
    "certainty_string" : string | null,
    "category_string" : string |null
  }
  
  export interface mediaBlockType {
    type?: mediaType;
    media?: string;
    media_md?: string;
  }
  
  export enum mediaType {
      IMG = "img", // Default
      PDF = "pdf",
  }

  export type IAlertStateDetail = {

  [key in LanguageSet]: IAlert;

};

  // Define the login State
  export interface IAlertState {
    readonly alert: IAlertStateDetail
    readonly setLang: LanguageSet; 
    readonly enableLangLoading: boolean;
}



// Define the initial state
const initialLoginState: IAlertState  = {
  alert : {
    en : <IAlert> {},
    es : <IAlert> {},
  },
  setLang : LanguageSet.EN,
  enableLangLoading : true
}


export const alertReducer: Reducer<IAlertState ,AlertAction > = (
    state = initialLoginState,
    action
  ) => {

    switch (action.type) {
        case AlertActionTypes.FETCH_ALERT: {
            return {
              ...state,
              alert :{
                en : action.alert,
                es : <IAlert>{}
              },
              setLang : LanguageSet.EN,
              enableLangLoading : false
            };
          }
        case AlertActionTypes.ENABLE_LOADING: {
          return {
            ...state,
            enableLangLoading : true
          }
        }
        case AlertActionTypes.LOADING_DONE: {
          return {
            ...state,
            enableLangLoading : false,
            setLang : action.setLang
          }
        }
        case AlertActionTypes.SET_LANGUAGE: {
          return {
            ...state,
            alert :{
              ...state.alert,
              es : action.alert
            },
            enableLangLoading : false,
            setLang : action.setLang
          };
        }
        default:
            return state;
    }
};