import React, { useState} from "react";
import {Col, Row, Form, Drawer,Input, Button, InputNumber, Select, Typography} from "@eb/antd";
import {EditOutlined} from '@ant-design/icons';
import {pageTitles,_appURL} from "../actions/default.config";
import "./setting-page.css";
import {getUserSettings,getUserLocations,setUserSettings} from "actions/user-settings";
import { RouteComponentProps,Link } from 'react-router-dom';
import { connect,ConnectedProps,MapDispatchToProps} from 'react-redux';
import { IAppState } from '../store/store';


// Create the containers interface
interface DispatchProps {
  getUserSettings : typeof getUserSettings ,
  getUserLocations : typeof getUserLocations,
  setUserSettings : typeof setUserSettings
}

interface OwnProps extends RouteComponentProps{}

const SettingPage: React.FunctionComponent<SettingPageProps> = ({...props}) => {

  const [form] = Form.useForm();

  const [drawer, setDrawer] = useState<{visible: boolean, placement : string,title : string,field:string,value: string | undefined,rules : any  }> (
    { visible: false, placement: 'left',title : '',field:'',value: '',rules : [] }
    );
 
  const [subCount, setsubCount] = useState(0)

  const [SuccessMsg, setSuccessMsg] = useState("")

  const {Text, Title } = Typography;

  const { Option } = Select;

  const {settings,getUserSettings,getUserLocations,setUserSettings} =  props;

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 11},
  };

  const  language = {
    "en" : "English",
    "es" : "Spanish",
  }

  // @ts-ignore
  const _selectedLan  = language[settings?.user?.language] ; 

    //@ts-ignore
  var locationCount = settings?.locations?.length;

  document.title = pageTitles.account

  function formatPhone(txt:string){
    var formatted = "";
  
   if(txt !==undefined){
    txt.toString();
    for(let i=0;i < txt.length;i++){
      if (i==3 || i==6)
        formatted+=" "+txt[i];
      else 
      formatted +=txt[i]
    }
  }
    return formatted;
  }

  const onFinish = (values: any) => {
    setUserSettings(values).then((response: any) =>{ 
      setSuccessMsg("Successfully updated");
      onClose(); 
    });
  };

  React.useEffect(() => {
    getUserSettings();
    getUserLocations();
  }, [])

  React.useEffect(() => {
    if(settings?.locations  !==undefined){
        var lsubcount = 0;
        //@ts-ignore
        locationCount = settings.locations.length;
        var location_setting = settings.locations;
        for(let i=0;i < locationCount ; i++){
          //@ts-ignore
          lsubcount += location_setting[i].subscriptions.length;  
        }
        setsubCount(lsubcount)
    }
  }, [settings?.locations])

 //server side validation
  React.useEffect(() => {
    form.setFields([
      {
        name: 'email',
        errors: settings?.user?.error.email,
      },
   ]);
  }, [settings?.user?.error])

  const onClose = () => {
    setDrawer(
    {
      ...drawer,
      visible: false,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const editFullName = () => {

    setSuccessMsg("");

    setDrawer(
      {
        ...drawer,
        visible: true,
        title : "Full Name",
        field : "full_name",
        value : settings?.user?.full_name ,
        rules : [ 
          { 
          type: 'string', 
          required: true, 
          message: 'Please select your Full Name!' 
          },
        ],
      });
  }
  
  const editEmail = () => {

    setSuccessMsg("");
    
    setDrawer(
      {
        ...drawer,
        visible: true,
        title : "Email",
        field : "email",
        value : settings?.user?.email ,
        rules : [ 
            { 
            type: 'email', 
            message: 'Email address is not properly formatted' ,
            },
            {
              min : 0,
              max : 75,
              message: 'Value is too long'
             },
        ],
      });
  }

  const editCellPhone = () => {

    setSuccessMsg("");
    
    setDrawer(
      {
        ...drawer,
        visible: true,
        title : "Mobile Phone",
        field : "cell_phone",
        value : (settings?.user?.cell_phone)?.toString().replace(/-/g,"") ,
        rules : [
          { 
          type: 'number',
          }
        ]
      });

  }

  const langList: any[] = [];

  Object.entries(language).forEach(([key, val]) => {
    langList.push(<Option value={key} key={key}>{val}</Option>);
  })

  const editLang = () => {

    setSuccessMsg("");
    
    setDrawer(
      {
        ...drawer,
        visible: true,
        title : "Language",
        field : "language",
        value : _selectedLan,
        rules : []
      });

  }


  const editLocation = () => {
    setSuccessMsg("");
    
    setDrawer(
      {
        ...drawer,
        visible: true,
        title : "",
        field : "location",
        value : "",
        rules : []
      });
  }

  const LocationList = () => {
  
    var locations : any = settings.locations! ;
    var locationsList : any  =[];
    return (
      <React.Fragment>
       
        <h1>Locations</h1>
        <Link to="#">+ Add Location</Link>
        { locations.forEach((location: { location_name: any; subscriptions: string | any[]; }) => locationsList.push(
          
        <Row justify="center">
          <Col span={24}>
                <Title className="location_name" level={5}>{location.location_name}<a href="#"><EditOutlined /></a></Title>
                <Text type="secondary">{location.subscriptions.length} Subscriptions</Text>
          </Col>
        </Row>)
        )
        }
        {locationsList}
      </React.Fragment>
        )
  }

  return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          <Col span={24}>
  
          <Title level={1}>Setting</Title>
            {/* Form Row Starts */}
            <Row justify="center">
            <Text type="success">{SuccessMsg}</Text>
              <Col span={24}>
                    <label>Full Name</label>
                    <Title level={5}>{settings?.user?.full_name}  <a onClick={editFullName} ><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Email</label>
                    <Title level={5}>{settings?.user?.email ==="" ? "Add One" : settings?.user?.email  } <a onClick={editEmail} ><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Mobile Phone</label>
                    <Title level={5}>+1 {settings?.user ? formatPhone(settings.user?.cell_phone) : ""}<a onClick={editCellPhone}><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Language</label>
                    <Title level={5}>{_selectedLan}<a onClick={editLang}><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Home Phone</label>
                    <Title  level={5}>+1 {settings?.user ? formatPhone(settings.user?.home_phone) : ""}<a href="#"><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Voice Message</label>
                    <Title level={5}>Home Only<a href="#"><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Phone Notification</label>
                    <Title level={5}>Off 10pm-7am<a href="#"><EditOutlined /></a></Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                    <label>Manage Subscription</label>
                    <Title level={5}>{locationCount} Location<a onClick={editLocation}><EditOutlined /></a></Title>
                    <Title level={5}>{subCount} Subscriptions</Title>
              </Col>
            </Row>
          </Col>
        </Row>
        <Drawer
          title={drawer.title}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={drawer.visible}
          key="left"
          getContainer={false}
          style={{ position: 'absolute' }}
          className="settingDrawer"
        >
          <a onClick={onClose} >{`< Back`}</a>
              <Row justify="center">  
                <Col span={24}>
                  <Form
                      {...layout}
                      form={form}
                      name="basic"
                      layout='vertical'
                      onFinish={onFinish}>
                            <Form.Item label={drawer.title}  rules={drawer.rules}  name={drawer.field}>
                            {(() => {
                                switch (drawer.field) 
                                {
                                  case "cell_phone":  return <InputNumber 
                                                              formatter={(value: any)=> `+1${value}`}
                                                                parser={(value: any) => value?.replace('+1', '')} 
                                                                  maxLength={12} style={{ width: 200 }} defaultValue={drawer.value} />;

                                  case "language":  return <Select defaultValue={drawer.value} style={{ width: 200 }}>
                                                    {langList}
                                                  </Select>;

                                  case "location":  return LocationList();

                                  default: return <Input defaultValue={drawer.value} />;
                                }
                            })()}
                           
                            </Form.Item>
                            {drawer.field !=='location' && (
                            <Form.Item>
                              <Button style={{ marginRight: '8px'}} type="primary" htmlType="submit">
                                Submit
                              </Button>
                              <Button  htmlType="button" onClick={onReset}>
                                Reset
                              </Button>
                              <Text type="danger">{settings?.user?.error?.common}</Text>
                            </Form.Item>)
                            }
                    
                      </Form>
                   </Col>
                </Row>
        </Drawer>
      </React.Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    settings : store.userSettingState.userSetting.settings
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  getUserSettings,
  getUserLocations,
  setUserSettings
};



const connector = connect(mapStateToProps,mapDispatchToProps);

type SettingPageProps = ConnectedProps<typeof connector>;

export default connector(SettingPage);


