import React from "react";
import {Footer} from "@eb/antd/es/layout/layout";
import {useTranslation} from "react-i18next";
import {Col, Row} from "@eb/antd";
import './footer.css';

const FooterComponent = (props: any) => {
  const {t} = useTranslation();

  const footerTag: any[] = [
    {
      i18nName: "company_overview",
      link: process.env.REACT_APP_FOOTER_LINK_COMPANY_OVERVIEW,
    },
    {
      i18nName: "contact_footer",
      link: process.env.REACT_APP_FOOTER_LINK_CONTACT,
    },
    {
      i18nName: "careers",
      link: process.env.REACT_APP_FOOTER_LINK_CAREERS,
    },
    {
      i18nName: "terms_of_service",
      link: process.env.REACT_APP_FOOTER_LINK_TOS,
    },
    {
      i18nName: "privacy_policy",
      link: process.env.REACT_APP_FOOTER_LINK_PRIVACY_POLICY,
    },
    {
      i18nName: "faqs",
      link: process.env.REACT_APP_FOOTER_LINK_FAQS,
    },
  ];

  const lastIdx: number = footerTag.length - 1;
  const footerLinkURL: string = `${process.env.REACT_APP_FOOTER_LINK_BASE}`;

  return (
    <Footer style={inStyles.footer}>
      <Row
        justify="center"
        gutter={{ xs: 12, sm: 16 }}
        style={inStyles.tagGroup}
      >
        {footerTag.map((tag, idx) => (
          <Col
            key={idx}
            style={
              idx === lastIdx
                ? { ...inStyles.tags, borderRight: "0px" }
                : { ...inStyles.tags }
            }
          >
            <a
              className="footer-link"
              href={`${footerLinkURL}/${tag.link}`}
              target="_blank"
              rel="noreferrer"
              style={inStyles.tagLink}
            >
              {t(tag.i18nName)}
            </a>
          </Col>
        ))}
      </Row>

        <Row justify="center">
          <Col span={24} style={{textAlign: "center"}}>
            <div style={inStyles.tagline}>{`${t("powered_by_nixle")} 2021 ${t(
                "company_name"
            )}`}</div>
          </Col>
        </Row>
      </Footer>
  );
};

export default FooterComponent;

const inStyles = {
  footer: {
    backgroundColor: "#1F3440",
    color: "#FCFDFD",
  },
  tagline: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  tagGroup: {
    marginTop: "80px",
  },
  tagLink: {
    color: "inherit",
  },
  tags: {
    textAlign: "center" as const,
    borderRight: "1px solid #FCFDFD",
  },
};
