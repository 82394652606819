import React  from "react";
import PublicationSidebar from "../components/Publication-sidebar-component";
import PublicationMainContent from "../components/Publication-maincontent-component";
// @ts-ignore
import {RouteComponentProps,useParams} from "react-router-dom";
import {Col, Row} from "@eb/antd";
import {pageTitles} from "../actions/default.config";
import { connect,ConnectedProps,MapDispatchToProps} from 'react-redux';
import {fetchPubDataByID,getTranslatedText} from "../actions/alert";
import { IAppState } from '../store/store';

// Create the containers interface
interface DispatchProps {
  fetchPubDataByID : typeof fetchPubDataByID 
  getTranslatedText : typeof getTranslatedText
}
interface OwnProps extends RouteComponentProps{}

const PublicationPage: React.FunctionComponent<PublicationPageProps> = ({...props}) => {
  // @ts-ignore
  let {pubId} = useParams();

  const {alertData,setLang,enableLangLoading,fetchPubDataByID,getTranslatedText} = props;

  const pubData: any = alertData;

  React.useEffect(() => {
    // Updating Title
    document.title = pageTitles.alert + (pubData?.en?.headline || '');

  }, [pubData]);

  React.useEffect(() => {
    fetchPubDataByID(pubId);
  }, []);

  return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={24} md={16}>
            <PublicationMainContent getTranslatedText={getTranslatedText} enableLangLoading={enableLangLoading} setLang={setLang} pubdata={pubData}/>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <PublicationSidebar enableLangLoading={enableLangLoading} pubdata={pubData} setLang={setLang}  getTranslatedText={getTranslatedText}  />
          </Col>
        </Row>
      </React.Fragment>
  );
};


const mapStateToProps = (store: IAppState) => {
  return {
    alertData: store.alertState.alert,
    setLang : store.alertState.setLang,
    enableLangLoading : store.alertState.enableLangLoading
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  fetchPubDataByID,
  getTranslatedText
};

const connector = connect(mapStateToProps,mapDispatchToProps);

type PublicationPageProps = ConnectedProps<typeof connector>;

export default connector(PublicationPage)


