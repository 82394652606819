/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
 import { applyMiddleware, combineReducers, createStore } from 'redux';
 import { Persistor ,persistReducer, persistStore } from 'redux-persist'
 import storage from 'redux-persist/lib/storage'

 /*  Thunk
 Redux Thunk middleware allows you to write action creators that return a function instead of an action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met. The inner function receives the store methods dispatch and getState as parameters.
 */
 import thunk from 'redux-thunk';
 import { composeWithDevTools } from 'redux-devtools-extension';
 // Import reducers and state type
 import {
   loginReducer,
   ILoginState,
 } from '../reducers/login';

 import {
    userReducer,
    IUserState,
  } from '../reducers/user';

 import {
  agencyReducer,
  IAgencyState
 } from '../reducers/agency';

 import {
  searchReducer,
  ISearchState} 
  from '../reducers/search';

 import {
   alertReducer,
   IAlertState} 
   from "../reducers/alert";

 import {
   applicationReducer,
   IApplicationState} 
   from "../reducers/application";

   import  {
     userSettingReducer,
     IUserSettingState
   } from "../reducers/user-settings"

 // Create an interface for the application state
 export interface IAppState {
   loginState: ILoginState,
   userState: IUserState,
   agencyState : IAgencyState,
   searchState : ISearchState,
   alertState : IAlertState,
   applicationState : IApplicationState,
   userSettingState : IUserSettingState
 }

 const persistConfig = {
  key: 'login',
  storage,
  whitelist :  ['userState','loginState']
}
 

 // Create the root reducer
 const rootReducer = combineReducers<IAppState>({
 loginState : loginReducer,
 userState : userReducer,
 agencyState: agencyReducer,
 searchState: searchReducer,
 alertState: alertReducer,
 applicationState : applicationReducer,
 userSettingState :userSettingReducer
 });

 const persistedReducer = persistReducer(persistConfig, rootReducer)
 export type RootState = ReturnType<typeof rootReducer>

 // Create a configure store function of type `ñ`
 export default function configureStore(): any {
   const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
   let persistor = persistStore(store)
   return { store, persistor }
 }