import React from "react";
import {useTranslation} from "react-i18next";

const PubSidebarTextATip = (props: any) => {
  const {t} = useTranslation();
  const isTippingActive = props.isActive;
  const tippingKeyword = props.keyword;
  const tippingURL = props.tippingUrl;
  return (
    <React.Fragment>
      {isTippingActive && (
        <div>
          {/*  Text a tip Section Section  */}
          {/* @todo Fix the static 88877 no. */}
          <b>{`${t("text_a_tip")} - ${t("text")} ${tippingKeyword} ${t(
            "follow_by"
          )} 888777.`}</b>
          <br />
          <a href={tippingURL} target="_blank" rel="noreferrer">
            {t("submit_tip")}
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default PubSidebarTextATip;
