import React, {useContext} from "react";
import { RouteComponentProps } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {Alert, Button} from "@eb/antd";
import {verifyUserLoginToken,resetLoginFlags}  from "actions/login";
import { connect,MapDispatchToProps,ConnectedProps} from 'react-redux';

import { IAppState } from '../store/store';

interface DispatchProps {
  verifyUserLoginToken: typeof verifyUserLoginToken
  resetLoginFlags: typeof resetLoginFlags
}

interface OwnProps extends RouteComponentProps{}
const VerifyPage: React.FunctionComponent<VerifyPageProps> = ({...props}) => {
  const {t} = useTranslation();
  const history = useHistory();
  // @ts-ignore
  let {tokenId} = useParams();

  const {login,verifyUserLoginToken,resetLoginFlags } = props;

  React.useEffect(() => {
    verifyUserLoginToken(tokenId);
  }, []);

  const isError: boolean = login.phoneNoValidateError;
  const errorMsg: string = login.errorMsg;

  const proceedToHome: boolean =
      login.phoneNoSubmit &&
      login.phoneNoLinkSent &&
      login.phoneNoValidated &&
      login.isLoggedIn;


  const handleLoginRedirect = () => {
    resetLoginFlags();
    setTimeout(() => {
      return history.push(`/login/`);
    }, 0)
  }



return (
    <>
      {
        proceedToHome ? <Redirect to="/home" /> :
              <>
                <div style={{textAlign: "center"}}>
                  <h3>Please wait while we check verify your link</h3>
                </div>
                <div>
                  {isError && <Alert
                      message="Error"
                      showIcon
                      description={errorMsg}
                      type="error"
                      action={<Button size="small" onClick={handleLoginRedirect}>
                        Retry Login
                      </Button>}/>

                  }
                </div>
              </>
        }
      </>
  );
};

const mapStateToProps = (store: IAppState,props: OwnProps) => {
  return {
    login: store.loginState.login,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  verifyUserLoginToken,
  resetLoginFlags
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type VerifyPageProps = ConnectedProps<typeof connector>;

export default connector(VerifyPage)