import React, {useEffect, useState} from "react";
import logo from "./../../logo.png";
import "./header.css";

// import { Link as RouterLink } from "react-router-dom";
import {Header} from "@eb/antd/es/layout/layout";
import {Drawer, Menu, Button, Row, Col, Dropdown} from "@eb/antd";
import {MenuOutlined} from "@ant-design/icons";
import {Link, NavLink} from "react-router-dom";
import {updateDimensions} from "actions/application"
import { connect} from 'react-redux';
import { IAppState } from '../../store/store';
import {navLinks} from '../../actions/default.config'


const HeaderComponent= (props: any) => {
  const {login,application,updateDimensions} = props;
  // @ts-ignore

  // handling window resize dimensions logging
  const isUserLoggedIn: boolean = login.isLoggedIn;
  const handleLogoClick = () => {
    // Redirect to Nixle External Page
    window.location.replace(`${process.env.REACT_APP_NIXLE_PAGE}`);
  }

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("resize", updateDimensions);
    return () => {
      // @ts-ignore
      window.removeEventListener("resize", updateDimensions);
    }
  });
  // Ends handling window resize dimensions logging

  const headerMenuBreakPoint: number = 768;


  const [mobileMenuOpen, setIsMobileMenu] = useState(false);

  const renderNavLinks = (isMobile: boolean) => {
    // @todo add the login variables post implementation

    const navLinkList = navLinks.filter((l) => {
      return isUserLoggedIn === l.isPrivate && l.type === 'nav';
    });

    return (
        <Row className="header-right-box" justify={isMobile ? "center" : "end"} gutter={[16, 16]}>
          <Col span={24}>
            <nav aria-label="Menu" role="navigation">
            {navLinkList.map((l, idx) => {
              return (
                  <span className="header-links" key={l.linkName}>
                    {
                      l.isExternal ? <a href={l.linkTo}> {l.linkName} </a> : <NavLink to={l.linkTo}>{l.linkName}</NavLink>
                    }
                  </span>
              );
            })}
            </nav>
          </Col>
        </Row>
    );
  };

  const toggleMobileMenu = (mode?: boolean) => {
    setIsMobileMenu((prevState) => {
      return mode || !prevState;
    });
  };

  const menu = (
      <nav aria-label="Menu" role="navigation">
      <Menu className="mobile-menu">
        {navLinks.map((l, idx) => {
            if(l.isPrivate === isUserLoggedIn){
              return (
                  <Menu.Item key={l.linkName}>
                    <a href={l.linkTo}>
                      {l.linkName}
                    </a>
                  </Menu.Item>
              )
            }

        }) }
      </Menu>
      </nav>
  );
  const homePage: string = `${process.env.REACT_APP_FOOTER_LINK_BASE}`;
  return (
      <Header style={inlineStyles.header}>
        <div className="header-container">
        <a className="skip-main" href="#main">Skip to main content</a>
          <Row>
            <Col span={8}>
              <div className="logo-container">
              <a href={`${homePage}`} > <img onClick={handleLogoClick} src={logo} style={inlineStyles.logo} alt="Everbridge Logo"/></a>
              </div>
            </Col>
            <Col span={16} style={{textAlign: "right"}}>
              {application.windowWidth >= headerMenuBreakPoint ? (
                  <>{renderNavLinks(false)}</>
              ) : (
                  <>
                    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                      <Button type="link">
                        <MenuOutlined style={{fontSize: '24px', color: 'white'}}/>
                      </Button>
                    </Dropdown>
                  </>
              )}
            </Col>
          </Row>
          <Drawer
              title="Nixle"
              placement="left"
              closable={false}
              onClose={() => toggleMobileMenu(false)}
              visible={mobileMenuOpen}
          >
            {renderNavLinks(true)}
          </Drawer>
        </div>
      </Header>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    login: store.loginState.login,
    application : store.applicationState.application
  };
};

const mapDispatchToProps = {
  updateDimensions
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HeaderComponent)



const inlineStyles = {
  header: {
    height: "64px",
    backgroundColor: "#212E35", // Old #F9FBFB
    //boxShadow: "0px 0px 8px #6D7878",
  },
  logo: {
    maxHeight: "56px",
    cursor: "pointer"
  },
};
