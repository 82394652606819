import { Reducer } from 'redux';
import { AgencyActions, AgencyActionTypes} from "../actions/agency";
import {mediaBlockType} from "./common.interface"

export interface IAgency {
    "id": null | number;
    "name": string;
    "urlname": string;
    "phone": string;
    "phone2": string;
    "addr": string;
    "suite": string;
    "city": string;
    "state": string;
    "zipcode": string;
    "latitude": null | number;
    "longitude": null | number;
    "has_tipping": boolean;
    "tipping_keyword": string;
    "facebook_comments_enabled": boolean;
    "logo": string;
    "logo_sm": string;
    "logo_md": string;
    "logo_lg": string;
  }

export interface IAgencyPubs {
    next: null | string;
    previous: null | string;
    results: pubShortDetailType[];
}

export interface pubShortDetailType {
  id: number;
  headline: string;
  pub_type: string;
  published_str: string;
  media: mediaBlockType[];
}

export interface IAgencyDetailState {
  details : IAgency,
  pubsByAgency : IAgencyPubs,
  searchString : string
}
  // Define the login State
export interface IAgencyState {
    readonly agency: IAgencyDetailState
}

// Define the initial state
const initialLoginState: IAgencyState = {
   agency:{
    details : <IAgency>{},
    pubsByAgency : { next : null,previous:null,results:[]},
    searchString : ''
   }
}


export const agencyReducer: Reducer<IAgencyState, AgencyActions> = (
    state = initialLoginState,
    action
  ) => {
    switch (action.type) {
        case AgencyActionTypes.FETCH_DETAIL: {
            return {
              ...state,
              agency :{
                ...state.agency,
                details : action.details,
                searchString : action.searchString,
              }
            };
          }
          case AgencyActionTypes.FETCH_PUBS: {
            return {
              ...state,
              agency :{
                ...state.agency,
                pubsByAgency :action.pubsByAgency
              }
            };
          }
        default:
            return state;
    }
  };