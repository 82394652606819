import React from "react";
import {useTranslation} from "react-i18next";
import Search from "@eb/antd/es/input/Search";
import {Redirect, useHistory} from "react-router-dom";

const PubSidebarSearch = (props: any) => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleOnSearch = (q: string) => {
    return history.push(`/search/publication/${q}`);
  }

  return (
    <React.Fragment>
      {/* Temporary Disable
       <div>
        <b>{t("nav_discover")}</b>
        <Search  placeholder="Enter zip or state code" onSearch={handleOnSearch} enterButton />
      </div> */}
    </React.Fragment>
  );
};

export default PubSidebarSearch;
