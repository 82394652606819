import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./en/index.json";
import es from "./es/index.json";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {translation: en},
  es: {translation: es}
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export const changeLanguage = (lang: any) => {
  i18n.changeLanguage(lang);
  document.documentElement.lang = lang;
}

export default i18n;