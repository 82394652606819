import React, {useState} from "react";
import SearchResultMainContent from "../components/Result-maincontent-component";
import SearchResultSidebar from "../components/Result-sidebar-component";

// @ts-ignore
import {useLocation, useParams,RouteComponentProps} from "react-router-dom";
import {Col, Row} from "@eb/antd";
import {pageTitles} from "../actions/default.config";
import { connect,ConnectedProps,MapDispatchToProps} from 'react-redux';
import {searchByString} from "../actions/search";
import { IAppState } from '../store/store';

// Create the containers interface
interface DispatchProps {
  searchByString : typeof searchByString 
}
interface OwnProps extends RouteComponentProps{}

const ResultPage: React.FunctionComponent<ResultPageProps> = ({searchData,searchByString,...prop}) => {

  const [pathName, setPathName] = useState('');
  const location = useLocation()

  // @ts-ignore
  let {searchString} = useParams();

  //const searchData: searchResultType = contextValue.searchResult;
  // Check if search word api response received

  // Updating Title
  document.title = pageTitles.searchPublication;


  React.useEffect(() => {
    if (pathName !== location.pathname) {
      setPathName(location.pathname);
      searchByString(searchString, '');
    }
  }, [location])

  return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={24} md={16}>
            <SearchResultMainContent
                payload={searchData}
                isLoaded={searchData.searchString === searchString}
                searchByString ={searchByString}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <SearchResultSidebar/>
          </Col>
        </Row>
      </React.Fragment>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    searchData: store.searchState.pubs
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  searchByString
};

const connector = connect(mapStateToProps,mapDispatchToProps);

type ResultPageProps = ConnectedProps<typeof connector>;

export default connector(ResultPage)


