import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import axios from "axios";
import {IUserSetting,IUserLocation,IUserSettingState} from "../reducers/user-settings"
import {_baseURL,_env} from "./default.config"
import {store} from '../index';

const validatedHeaderParams: any = {
  credentials: 'include',
  headers: {
    mode: 'cors'
  }
};

  export enum UserSettingActionTypes {
    GET_SETTING = "get_setting",
    GET_LOCATIONS = "get_locations",
    SET_ERROR = "set_error",  
}

export interface IValidateUserSettingAction {
  type: UserSettingActionTypes;
  setting: { user ?: IUserSetting, locations ?: IUserLocation[]};
}

export type UserSettingActions = IValidateUserSettingAction;

export const getUserSettings: ActionCreator<
ThunkAction<Promise<any>, IUserSettingState, null, IValidateUserSettingAction>
> = () => {
  // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_USER_SETTING;

  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(URL, validatedHeaderParams);

      if(response.status ===200){
        dispatch({
          setting: { user : response.data},
          type: UserSettingActionTypes.GET_SETTING,
        });
     }

    } catch (err) {
      console.error(err);
    }
  };
}

export const getUserLocations: ActionCreator<
ThunkAction<Promise<any>, IUserSettingState, null, IValidateUserSettingAction>
> = () => {
  // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_USER_LOC;

  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(URL, validatedHeaderParams);

      if(response.status ===200){
        dispatch({
          setting: {locations: response.data.results},
          type: UserSettingActionTypes.GET_LOCATIONS,
        });
     }

    } catch (err) {
      console.error(err);
    }
  };
}

export const setUserSettings = (data : Object ) => {

  // @ts-ignore
  const URL = _baseURL + _env.REACT_APP_API_USER_SETTING;

  return async (dispatch: Dispatch) => {

    return new Promise((resolve, reject) => {
        // @ts-ignore
      const csrfURL =  _baseURL + '/magic/csrf';
      axios.get(csrfURL)
      .then(async (response:any) => {
       Object.assign(validatedHeaderParams.headers, {"X-CSRFToken": response.data});
       try {
        const result = await axios.post(URL, data,validatedHeaderParams);
        if(result.status ===200){
          dispatch({
            setting: { user : result.data},
            type: UserSettingActionTypes.GET_SETTING,
          });
          resolve(result.data.length);
        } }
       catch (err:any) {
        if(err.response.status ===400){
          dispatch({
            setting: { user :{error: err.response.data}},
            type: UserSettingActionTypes.SET_ERROR,
          });
         }
         else {
          dispatch({
            setting: { user :{error: {common:"Something went wrong, try again"}}},
            type: UserSettingActionTypes.SET_ERROR,
          });
         }
         reject();
      }
      });
    });
    } 
  }