import React, {useContext} from "react";
import {LanguageSet} from "../actions/default.config";

import {useTranslation} from "react-i18next";
import MediaLoader from "./Media-loader-component";
import {Card, Col, Row, Image, Space,Skeleton} from "@eb/antd";
import FacebookCommentsPluginV2 from "./FB_social_media_plugin";
import AlertTag from "./structure/Alert-tag";

const PublicationMainContent = (props: any) => {

  const {t} = useTranslation();
  const agencyURL = process.env.REACT_APP_CITIZEN_PAGE;

  const _selectedLang: LanguageSet = props.setLang;
  const _pubData: any = props.pubdata ? props.pubdata[_selectedLang] : null;
  const isExpired: boolean = _pubData
      ? _pubData?.is_expired || _pubData?.is_deactivated
      : false;

  const isFbCommentsEnable =
      !isExpired && !!_pubData?.agency?.facebook_comments_enabled;

  const isAlertType: boolean = _pubData?.pub_type === 2;

  const isAlertDetailsActive: boolean = !!(
      _pubData?.severity_string ||
      _pubData?.category_string ||
      _pubData?.certainty_string ||
      _pubData?.cap_event ||
      _pubData?.urgency_string
  );

  return (
      <React.Fragment>
        <Row gutter={[24, 24]}>
          {isExpired ? (
              <Col span={24}>
                <div style={styles.headerAlertStyle}>{t("message_expired")}</div>
              </Col>
          ) : null}

          <Col span={24}>
            {_pubData?.agency?.name ? (
                <>
                  <a href={`${agencyURL}/${_pubData?.agency?.urlname}`}>
                    <h2 style={styles.headerH2}>{_pubData?.agency?.name}</h2>
                  </a>

                  <span>
              {_pubData?.published_str}
            </span>
                </>
            ) : (
              <Space>
                <Skeleton.Input
                    style={{width: 280}}
                    active={true}
                    size="default"
                />
              </Space>
          )}
          </Col>

          <Col span={4}>
            {_pubData?.agency?.logo ? (
                <a href={`${agencyURL}/${_pubData?.agency?.urlname}`}>
                  <Image alt="Agency Logo" src={_pubData?.agency?.logo} preview={false}/>
                </a>
            ) : (
              <Space>
                <Skeleton.Image style={{width: 103}}/>
              </Space>
          )}
          </Col>


          {_pubData?.headline ? (
              <Col aria-labelledby="headline" span={20}>
                <Row gutter={[8, 18]}>
                  <Col span={24}>
                    {/* Headline */}
                    <span style={styles.headerTitle}>
                    {_pubData?.headline}
                  </span>
                    {/* Ends Headline */}
                  </Col>
                  <Col span={24}>
                    <AlertTag type={_pubData?.pub_type}/>
                  </Col>
                </Row>
              </Col>
          ) : null}

          <Col aria-labelledby="Alert Content">
            <Row>
              <Col xs={{span: 0, offset: 0}} lg={{span: 4, offset: 0}}>
                {/*  For Spacing to Left in wide screen */}
              </Col>
              <Col xs={{span: 24, offset: 0}} lg={{
                span: 20


                , offset: 0
              }}>
                <Row gutter={[8, 24]}>
                  {_pubData?.media ? (
                      // @info: If No media available don't render the empty <Col> tags, display unwanted blank space
                      _pubData?.media.length > 0 ? (
                          <Col span={24}>
                            <MediaLoader media={_pubData?.media}/>
                          </Col>
                      ) : null
                  ) : (
                    <Col span={24}>
                      <Space>
                        <Row align="top" gutter={[16, 16]}>
                          <Col>
                            <Skeleton.Image style={{width: 250, height: 160}}/>
                          </Col>
                          <Col>
                            <Skeleton.Image style={{width: 250, height: 160}}/>
                          </Col>
                        </Row>
                      </Space>
                    </Col>
                )}
                </Row>

                {_pubData?.pub_text ?
                    <Row>
                      <Col>
                        <div dangerouslySetInnerHTML={{__html: _pubData?.pub_text}}
                             style={{marginTop: "20px"}}></div>
                      </Col>
                    </Row> : ''
                }

                {_pubData?.cap_instructions ?
                    <Row>
                      <Col style={{marginTop: "20px"}}>
                        <b> {t("instructions")}</b>
                        <div>{_pubData?.cap_instructions}</div>
                      </Col>
                    </Row> : ''
                }


                {_pubData?.incident_entered ?
                    <Row>
                      <Col style={{marginTop: "20px"}}>
                        <b> {t("incident_location")}</b>
                        <div>{_pubData?.incident_entered}</div>
                      </Col>
                    </Row> : ''
                }

                {_pubData?.contact_name ?
                    <Row>
                      <Col style={{marginTop: "20px"}}>
                        <b> {t("publication_contact")}</b>
                        <div>{_pubData?.contact_name}</div>
                        <div>{_pubData?.contact_email}</div>
                        <div>{_pubData?.contact_phone}</div>
                      </Col>
                    </Row> : ""
                }

                {
                  isAlertType && isAlertDetailsActive && <Row>
                      <Col span={24} style={{marginTop: "20px"}}>
                          <Card
                              size="small"
                              title={t("cap_details")}
                              style={{width: "80%"}}
                          >
                              <div>
                                  <b>{t("severity")}: </b>
                                {_pubData?.severity_string}
                              </div>
                              <div>
                                  <b>{t("urgency")}: </b>
                                {_pubData?.urgency_string}
                              </div>
                              <div>
                                  <b>{t("certainty")}: </b>
                                {_pubData?.certainty_string}
                              </div>
                              <div>
                                  <b>{t("category")}: </b>
                                {_pubData?.category_string}
                              </div>
                              <div>
                                  <b>{t("event")}: </b>
                                {_pubData?.cap_event}
                              </div>
                          </Card>
                      </Col>
                  </Row>
                }

                {
                  <Row>
                    <Col span={24}>
                      {isFbCommentsEnable && <FacebookCommentsPluginV2/>}
                    </Col>
                  </Row>
                }

              </Col>
            </Row>
          </Col>

        </Row>
      </React.Fragment>
  );
};

export default PublicationMainContent;


const styles: any = {
  headerAlertStyle: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    padding: "5px 0",
  },
  headerH2: {
    display: "inline-block", marginRight: "30px", marginBottom: '0px', lineHeight: '32px', fontSize: '20px'
  },
  headerTitle: {
    lineHeight: '32px', fontSize: '20px', fontWeight: '500'
  }
}
