import React from "react";
import {Menu} from "@eb/antd";
import {
  HomeFilled,
  SettingFilled,
  UserOutlined
} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {navLinks} from '../../actions/default.config'

const MenuComponent = (props: any) => {

  const navLinkList = navLinks.filter((l) => {
    return  l.isPrivate && l.type === 'side';
  });

  return (
      <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={true}
          style={{width: '65px'}}
      >
      {navLinkList.map((l, idx) => {
          return (
            <Menu.Item key="2" icon={l.linkName == "Setting" ? <SettingFilled/> : <UserOutlined/> }>
                {
                  <NavLink to={l.linkTo}>{l.linkName}</NavLink>
                }
            </Menu.Item>
          );
       })}
      </Menu>
  )
}

export default MenuComponent;