import React from "react";
import {useTranslation} from "react-i18next";

function AlertTag(props: any) {
  const {t} = useTranslation();
  // @todo Fix the mapping object switch
  const processAlertType: Function = (alertId: number): Object => {
    let response: {
      typeName: string;
      color: string;
    };

    switch (alertId) {
      case 2:
        response = {typeName: t("alert"), color: "#DC3545"};
        break;
      case 3:
        response = {typeName: t("advisory"), color: "#F4C120"};
        break;
      case 5:
        response = {typeName: t("Community"), color: "#F4C120"};
        break;
      default:
        response = {typeName: t("Unknown"), color: "#F4C120"};
    }
    return response;
  };

  const {typeName, color} = processAlertType(props?.type);

  return (
      <div style={{...typeTagStyle, backgroundColor: color}}>{typeName}</div>
  );
}

export default AlertTag;

const typeTagStyle: any = {
  color: "white",
  padding: "0px 10px",
  // borderRadius: "20px",
  width: "fit-content",
  textAlign: "center",
  display: 'inline-block'
};
