import React from "react";
import HeaderComponent from "../components/structure/Header-component";
import FooterComponent from "../components/structure/Footer-component";
import {Layout} from "@eb/antd";
import "./layout.css";
import LayoutSectionComponent from "../components/structure/Layout-section-component";

const LayoutPage = (props: any) => {
  //const href: string = window.location.href;
  const hostname: string = window.location.hostname;
  // @ts-ignore
  const isShortURL = hostname.indexOf(process.env.REACT_APP_SHORT_URL) >= 0;
  const preLoadScript = () => {

    if (isShortURL) {
      const pathname: string = window.location.pathname;
      // @ts-ignore
      const shortURLToken: string = pathname ? pathname.split("/").pop() : "";
      const verifyPath = /^\/0\/[a-zA-Z0-9]{5,}/; //e.g. evbg.us/0/<token>
      const alertPath = /^\/[a-zA-Z0-9]{5,}/; // e.g. evbg.us/<token>

      if (pathname.match(verifyPath)) {
        // Verify Page
        // @ts-ignore
        window.location = `${process.env.REACT_APP_APP_BASE_URL}validate/${shortURLToken}`;
      } else if (pathname.match(alertPath)) {
        // Alert Page
        // @ts-ignore
        window.location = `${process.env.REACT_APP_APP_BASE_URL}redirect/alert/${shortURLToken}`;
      }
    }
  };
  preLoadScript();

  return (
      <React.Fragment>
          <HeaderComponent/>
          <Layout className="layout">
            <LayoutSectionComponent>
            {props.children}
            </LayoutSectionComponent>
          </Layout>
          <FooterComponent/>
      </React.Fragment>
  );
};

export default LayoutPage;
